@use "../../../../../styles/base";
@use "../../../../../styles/breakpoint";

.container {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    @media (max-width: 1300px) {
        padding: 0px;
    }
}

.loader {
    box-sizing: border-box;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    background-color: #fff;
}

.error {
    box-sizing: border-box;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    font-size: 24px;
    color: base.$ptr-color-error;
    background-color: #fff;
}
