@use "../../../../../../styles/base";
@use "../../../../../../styles/breakpoint";

.outer {
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.outerClosed {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.mobileButton {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
}

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    @media (max-width: 1300px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.containerLeft {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    @media (max-width: 1300px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.containerRight {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.addSubscriptionBtn {
    margin-right: 8px;
    position: relative;
    top: -1px;
    @media (max-width: 1300px) {
        margin-bottom: 8px;
        margin-right: 0px;
    }
}

.metricFilter {
    margin-right: 8px;
    @media (max-width: 1300px) {
        margin-bottom: 8px;
        margin-right: 0px;
    }
}

.subscriberFilter {
    margin-right: 24px;
    @media (max-width: 1300px) {
        margin-bottom: 8px;
        margin-right: 0px;
    }
}

.subscriberLabel {
    width: 100px;
    min-width: 100px;
    font-size: 16px;
    font-weight: 700;
    color: #37474f;
}
