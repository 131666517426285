@use "../../../styles/base";
@use "../../../styles/breakpoint";

.sidebarContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(
        100vh - base.$ptr-navbar-height - 72px
    ); /** View port - nav bar  - sub nav*/
    min-height: 650px;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    border-top: none;
    background: #fff;
    /* Shadow/ptr-shadows-md */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    color: #37474f;
}

.header {
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    border-bottom: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    font-size: 20px;
    font-weight: 500;
}

.sidebarBodyContainer {
    box-sizing: border-box;
    padding: 24px;
    min-width: 268px;
    @include breakpoint.sm {
        width: 50%;
    }
    @include breakpoint.md {
        width: 50%;
    }
}

.detailsCardContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 96px;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    background-color: #fafafa;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    width: 220px;
    min-width: 220px;
    @include breakpoint.sm {
        width: 100%;
        align-items: center;
    }
    @include breakpoint.md {
        width: 100%;
        align-items: center;
    }
}

.detailsCardLabel {
    font-weight: 400;
}

.detailsCardValue {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
}

.fai {
    width: 16px;
    height: 16px;
}
