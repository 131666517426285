@use "../../../styles/base";
@use "../../../styles/breakpoint";

.container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #cfd8dc;
    background: #fff;
    width: 600px;
    @include breakpoint.sm {
        width: 100%;
        border-top: none;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.error {
    font-size: 18px;
    color: base.$ptr-color-error;
}

.headerText {
    color: #37474f;
    color: base.$ptr-blue-gray-800;
    font-size: 20px;
    font-weight: 500;
}

.bodyContentTitle {
    color: #37474f;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 12px;
}

.bodyContentValue {
    color: #37474f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
}

.bodyContentOne {
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cfd8dc;
    background: #fafafa;
    height: 110px;
    padding: 16px;
    display: flex;
    flex-direction: row;
}

.cOne_leftContainer {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.cOne_rightContainer {
    box-sizing: border-box;
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.bodyContentTwo {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cfd8dc;
    background: #fafafa;
    height: 110px;
    padding: 16px;
    display: flex;
    flex-direction: row;
}

.cTwo_leftContainer {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.cTwo_centerContainer {
    box-sizing: border-box;
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.bodyContentThree {
    box-sizing: border-box;
    width: 100%;
    height: 110px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.cThree_LeftContainer {
    box-sizing: border-box;
    padding: 16px;
    width: calc(50% - 8px);
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    background-color: #fafafa;
}

.cThree_rightContainer {
    box-sizing: border-box;
    width: calc(50% - 8px);
    margin-left: 8px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #cfd8dc;
    height: 100%;
    border-radius: 4px;
    background-color: #fafafa;
}

.cThree_titleValueContainer {
    overflow: hidden;
}

// mobile styling
.mobileBodyRow {
    box-sizing: border-box;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #cfd8dc;
    background: #fafafa;
    height: 110px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.mobileBodyLeft {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.mobileBodyLeft_FullWidth {
    box-sizing: border-box;
    width: 100%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.mobileBodyRight {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}

.noWrap {
    overflow: visible;
    white-space: nowrap;
}
