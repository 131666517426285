@use "../../../../../styles/base";

.container {
    box-sizing: border-box;
    width: 768px;
    min-width: 500px;
}

.queryLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 768px;
    height: 400px;
}

.queryError {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 768px;
    height: 400px;
    color: base.$ptr-color-error;
    font-size: 24px;
}

.empty {
    box-sizing: border-box;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    color: base.$ptr-blue-gray-800;
    background-color: #fff;
    font-weight: 400;
    font-size: 24px;
}
