.container {
    padding: 24px;
    width: 25rem;
}

.boldHeading {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #37474f;
}

.heading {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}

.phoneNumberInputLabel {
    font-weight: 700;
    color: #37474f;
}

.checkboxContainer {
    margin-bottom: 16px;
    margin-left: -8px;
}
