@use "../../styles/base";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: #{base.$global-val * 4}px;
    background-color: base.$ptr-gray-700;
}

.logo {
    height: #{base.$global-val * 4}px;
    width: 100px;
    background-color: black;
}

.navigationalControlsContainer {
    padding-left: 16px;
}

.appControlsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.standardNavigationalControlsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
}

.standardControlItem {
    margin-right: 8px;
}

.adminNavigationalControlsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    width: 210px;
}

.dropDownContainer {
    display: flex;
    flex-direction: row;
    margin-right: #{base.$global-val * 1}px;
}

.inviteUserContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 8px;
}

.userIcon {
    background-color: base.$ptr-color-success;
    color: base.$ptr-white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: #{base.$global-val}px;
    line-height: #{base.$global-val * 1.5}px;
    margin-right: #{base.$global-val * 0.25}px;
}

.DropdownMenuItem {
    position: relative;
    cursor: pointer;
    height: 32px;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DropdownMenuItem[data-highlighted] {
    background-color: #f5f5f5;
    outline: none;
}
