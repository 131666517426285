@use "../../../../../styles/base";
.tHeadContainer {
    width: 100%;
}

.tableHeaderRow {
    box-sizing: border-box;
    border-top: 1px base.$ptr-divider-type base.$ptr-divider-color;
    height: 50px;
    font-size: 16px;
    color: base.$ptr-font-color-dark;
    font-weight: 700;
    background-color: base.$ptr-gray-100;
}

.tHeadContainer th {
    box-sizing: border-box;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ruleName_Header {
    width: 300px;
}

.metric_Header {
    width: 350px;
}

.description_Header {
    min-width: 350px;
    width: calc(
        100vw - 1025px
    ); // 50px paddding around table, 25px for scrollbar vertical scroll bar, 950px for other columns
    overflow: hidden;
}

.author_Header {
    width: 200px;
}

.menu_Header {
    width: 100px;
}

.headerText {
    margin-right: 8px;
}

.sorted {
    color: base.$ptr-blue-600;
}
