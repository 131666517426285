@use "../../../styles/base";

.modal-overlay {
    background-color: hsla(200, 18%, 26%, 0.75);
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 20;
    box-sizing: border-box;
}

.modal-content {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #ffffff;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: base.$ptr-pagination-border-radius;
    opacity: 1;
}
