@use "../../../../styles/base";

.popoverContent {
    background-color: base.$ptr-blue-gray-800;
    z-index: 1000;
    box-sizing: border-box;
    color: base.$ptr-color-light;
    border-radius: base.$ptr-tooltip-border-radius;
    padding: base.$ptr-tooltip-padding-y base.$ptr-tooltip-padding-x;
    font-size: base.$ptr-font-size-x-small;
    font-weight: 400;
    line-height: 24px;
    max-width: base.$ptr-tooltip-max-width;
    text-align: left;
}

.popoverArrow {
    fill: base.$ptr-blue-gray-800;
    z-index: 1000;
}

.removeButtonStyling {
    all: unset;
}
