@use "../../../styles/base";

.empty-state-container {
    width: 100%;
    height: calc(100vh - (#{base.$global-val * 4}px));
    min-height: 634px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.empty-state-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: base.$ptr-white;
    width: 100%;
    max-width: 442px;
    height: 100%;
    max-height: 634px;
    border-radius: base.$ptr-radius-round;
    border: #{base.$ptr-divider-width} base.$ptr-divider-type base.$ptr-divider-color;
    padding: #{base.$global-val * 2}px;
    box-sizing: border-box;
}

.empty-state-card-image-container {
    width: 100%;
    max-width: 376px;
    height: 258px;
    box-sizing: border-box;
}

.empty-state-card-primary-text {
    font-size: base.$ptr-sizing-200rem;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    width: 100%;
    max-width: 376px;
    padding-top: #{base.$global-val * 1.5}px;
    color: base.$ptr-font-color-dark;
}

.empty-state-card-secondary-text {
    font-size: base.$ptr-sizing-100rem;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    text-align: center;
    max-width: 378px;
    padding-top: #{base.$global-val * 1.5}px;
    padding-bottom: #{base.$global-val * 1.5}px;
    color: base.$ptr-font-color-dark;
}
