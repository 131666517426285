@use "../../../styles/base";

.timer-cycle-container {
    padding-left: #{base.$global-val * 1.5}px;
    padding-right: #{base.$global-val * 1.5}px;
    padding-top: #{base.$global-val * 1.5}px;
    font-weight: 500;
    font-size: #{base.$global-val}px;
    color: base.$ptr-black;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    padding-bottom: 52px;
}

.timer-cycle-instructions-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: #{base.$global-val}px;
}

.timer-cycle-instructions-step1 {
    width: 218px;
    padding-right: #{base.$global-val}px;
}

.timer-cycle-input-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
}

.timer-cycle-input-row-inputs {
    padding-right: 16px;
}

.timer-cycle-summary-heading {
    padding-top: #{base.$global-val * 1.5}px;
    padding-bottom: #{base.$global-val}px;
}

// summary calculation styles
.timer-cycle-summary-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: #{base.$global-val}px;
    padding-top: #{base.$global-val}px;
    padding-bottom: #{base.$global-val}px;
    width: 452px;
    height: 88px;
    background: base.$ptr-gray-100;
    border-radius: base.$ptr-radius-round;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.timer-cycle-summary-calculation-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 124px;
    height: 56px;
    padding-right: 16px;
}

.timer-cycle-summary-border-right {
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-right-style: base.$ptr-divider-type;
    margin-right: 16px;
}

.timer-cycle-summary-calculation-text {
    font-weight: 400;
    font-size: #{base.$global-val}px;
    line-height: 24px;
    color: #000000;
}

.timer-cycle-summary-calculation-value {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: base.$ptr-blue-gray-600;
    display: flex;
}

.timerCycleSummaryValues {
    width: 25px;
    text-align: center;
    padding-right: 2px;
}
