@use "../../../../styles/base";
.formContainer {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
}

.loading {
    opacity: 0.5;
}

.textArea {
    width: 100%;
}
