@use "../../styles/breakpoint";
@use "../../styles/base";

.container {
    box-sizing: border-box;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #eceff1;
    height: calc(100% - 64px); // page size - header height
    overflow-y: auto;
    @include breakpoint.sm {
        padding: 0px;
    }
}

.wellIndexError {
    box-sizing: border-box;
    height: 673px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    font-size: 24px;
    color: base.$ptr-color-error;
    background-color: #fff;
}

.wellIndexLoader {
    box-sizing: border-box;
    height: 673px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    font-size: 24px;
    background-color: #fff;
}
