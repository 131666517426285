.dropDown-options-container {
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 15%);
    padding: 0.5rem 0 0.5rem 0;
    z-index: 1000;
    color: rgb(66, 66, 66);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
}

.dropDown-button-class {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dropDown-option {
    cursor: pointer;
    padding-left: 1rem;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropDown-option:hover {
    background-color: #f5f5f5;
}

.drop-down-disabled {
    opacity: 0.5;
}

// position classes
.navigation {
    right: 0.25rem;
}

.add-well-display-api-menu {
    right: -10%;
    top: -60%;
}

.navigationMobile {
    left: 0;
}

.iwp-sub-nav {
    left: 0;
    top: 30px;
}

.iwp-sub-nav-mobile {
    right: 0px;
    top: 30px;
}
