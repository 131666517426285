@use "../../../../../styles/base";

.container {
    box-sizing: border-box;
    width: 700px;
}

.body {
    box-sizing: border-box;
    padding: 24px;
    font-family: "Roboto";
    color: #424242;
    font-size: 16px;
    font-weight: 400;
}

.queryLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.queryError {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: base.$ptr-color-error;
    font-size: 24px;
}

.queryEmpty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: base.$ptr-blue-gray-800;
    font-size: 24px;
}

.title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 16px;
}

.label {
    font-size: 16px;
    font-weight: 700;
    margin-right: 4px;
}

.ruleName {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 4px;
}

.ruleDescription {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
}
