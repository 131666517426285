@use "../../../styles/base";
// check box
.checkBoxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.fontAwesomeCheckBox {
    position: absolute;
    left: 8px;
    top: 3px;
    width: 16px;
    height: 16px;
    color: #1e88e5;
    border-radius: border-box;
}

.fontAwesomeEmptyCheckBoxDisabled {
    opacity: 0.5;
}

.inputCheckbox {
    position: relative;
    width: 16px;
    height: 16px;
    background: transparent;
    appearance: none;
    top: 0px;
    left: 5px;
}

// These classes are invoked to add 'focus' effect.
// before using fix focus state is activated when clicking just outside of borders of FAI

// .inputCheckbox:focus{
//     box-shadow: 0px 0px 0px 2px black;
//     width: 16px;
//     height: 16px;
//     position: relative;
//     left: 4px;
//     border-radius: 1px;
//     top: -1px;
// }
// .inputCheckbox:active{
//     box-shadow: none;
// }

.checkboxLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 12px;
    color: base.$ptr-font-color-dark;
}

.checkboxDisabled {
    opacity: 0.5;
}

// radio button

.radioWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.fontAwesomeRadio {
    position: absolute;
    left: 8px;
    top: 3px;
    width: 16px;
    height: 16px;
    color: base.$ptr-color-primary;
    border-radius: border-box;
}

.inputRadio {
    position: relative;
    width: 16px;
    height: 16px;
    background: transparent;
    appearance: none;
    top: 0px;
    left: 5px;
}

.radioDisabled {
    opacity: 0.5;
}

.radioLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 12px;
    color: base.$ptr-font-color-dark;
}

// Toggle

.reactSwitch {
    vertical-align: middle;
    margin-left: 4px;
}

.toggleLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 12px;
    color: base.$ptr-font-color-dark;
}
