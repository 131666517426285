@use "../../../../../../styles/base";

.bodyContainer {
    padding: 24px;
    min-height: 300px;
}

.bodyContainerLoading {
    opacity: 0.5;
}

.bodyContainerError {
    min-height: 100px;
}

.row {
    margin-bottom: 16px;
}

.bodyText {
    color: #37474f;
    font-size: 16px;
    font-weight: 400;
}

.infoText {
    margin-top: 24px;
    color: #546e7a;
    font-size: 16px3;
    font-weight: 400;
}
