@use "../../../styles/base";

.container {
    box-sizing: border-box;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-top-style: base.$ptr-divider-type;
}

.inputRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

.input {
    padding-right: 16px;
}
