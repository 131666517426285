@use "../../../styles/base";

.container {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    padding-bottom: 16px;
}

.navControl {
    margin-left: 24px;
    margin-right: 12px;
}
