@use "../../../styles/base";

.confirmation-container {
    box-sizing: border-box;
    padding-left: #{base.$global-val * 1.5}px;
    padding-right: #{base.$global-val * 1.5}px;
    padding-top: #{base.$global-val * 1.5}px;
}

.confirmation-description-flex-row-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 88px;
    width: 100%;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    padding-bottom: 24px;
}

.confirmation-description-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 88px;
    width: 100%;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    margin-top: 24px;
    padding-bottom: 28px;
}

.confirmation-text-flex-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: #{base.$global-val}px;
    width: 140px;
    height: 100%;
}

.confirmation-smart-text-flex-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: #{base.$global-val}px;
    overflow-x: visible;
    height: 100%;
    width: 150px;
}

.confirmation-text-flex-column-heading {
    box-sizing: border-box;
    font-weight: 400;
    font-size: base.$ptr-font-size-2;
    line-height: 24px;
    color: base.$ptr-blue-gray-800;
    padding-bottom: 12px;
}

.confirmation-text-flex-column-value {
    box-sizing: border-box;
    font-weight: 700;
    font-size: base.$ptr-font-size-2;
    line-height: 24px;
    color: base.$ptr-blue-gray-800;
    padding-left: 12px;
}

.confirmation-timer-update-value {
    display: "flex";
    box-sizing: border-box;
    font-weight: 700;
    font-size: base.$ptr-font-size-2;
    line-height: 24px;
    color: base.$ptr-blue-gray-800;
    padding-left: 5px;
}

.confirmation-input-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}
