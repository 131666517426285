@use "../../../styles/breakpoint";
@use "../../../styles/base";

.modalContainer {
    width: 25rem;
}

.queryLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 200px;
}

.queryError {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: base.$ptr-color-error;
    font-size: 24px;
    padding: 24px;
    height: 200px;
}

.infoHeading {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}

.datePickerContainer {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @include breakpoint.sm {
        flex-direction: column;
    }
}

.datePickerLabel {
    font-size: 16px;
    font-weight: 700;
    min-width: 5rem;
    padding-bottom: 1rem;
    color: #37474f;
}

.marginRight24 {
    margin-right: 24px;
}

.toggleHeading {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}

.toggleContainer {
    display: flex;
    flex-direction: column;
}
