@use "../../styles/base";

.bodyContainer {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    box-sizing: border-box;
    height: 400px;
}

.bodyContainerHeader {
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
}

.headerName {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
    padding: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    width: 100%;
}

.row_leftContainer {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
}

.row_rightContainer {
    box-sizing: border-box;
    width: 50%;
    padding-left: 8px;
}

.userIcon {
    background-color: base.$ptr-color-success;
    color: base.$ptr-white;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: #{base.$global-val}px;
    line-height: #{base.$global-val * 1.5}px;
    margin-right: #{base.$global-val * 0.25}px;
    margin-right: 16px;
}

.organizationDiv {
    box-sizing: border-box;
    width: 212px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
}

.organizationDivHeading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #37474f;
}

.organizationDivText {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #37474f;
    padding-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rowContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: none;
}

.deactivateUser {
    width: 100%;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
    padding: 16px;
}

.passwordReset {
    width: 100%;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
    padding: 16px;
    margin-right: 16px;
}

.innerRowHeading {
    padding-bottom: 16px;
}

.headerEmailDiv {
    font-weight: 400;
    font-size: 18px;
    color: #37474f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userActivationToggleContainer {
    display: flex;
    flex-direction: row;
}

.userActivationText {
    margin-left: 6px;
    margin-top: 1px;
}
