@use "../../../styles/base";

.alert-left-icon {
    padding-right: 8px;
}
.alert-right-icon {
    padding-left: 8px;
    cursor: pointer;
}

.alert-left-icon-text-container {
    display: flex;
    align-items: center;
}

.alert-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: base.$ptr-alert-text-padding-x;
    border-width: base.$ptr-alert-border-width;
    border-style: base.$ptr-border-style;
    border-radius: base.$ptr-alert-radius;
    font-size: base.$ptr-alert-font-size;
    line-height: base.$ptr-alert-line-height;
    color: base.$ptr-font-color-dark;
}

.info {
    border-color: #b2dfdb;
    background-color: base.$ptr-teal-50;
}

.danger {
    border-color: base.$ptr-alert-error-border;
    background-color: base.$ptr-alert-error;
}

.warning {
    border-color: base.$ptr-alert-warning-border;
    background-color: base.$ptr-alert-warning;
}

.success {
    border-color: base.$ptr-alert-success-border;
    background-color: base.$ptr-alert-success;
}

.empty-state-card-banner {
    margin-top: #{base.$global-val * 1.5}px;
    max-width: 378px;
    width: 100%;
    height: 64px;
}
