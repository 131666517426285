@use "../../../styles/base";

.oneButtonFooter {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-top-style: base.$ptr-divider-type;
}

.twoButtonFooter {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-top-style: base.$ptr-divider-type;
}
