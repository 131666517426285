@use "../../../styles/base";

.subnavigation {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background-color: base.$ptr-white;
    padding: 16px 24px;
    height: 72px;
    width: 100vw;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
}

.subnavigationLeft {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-right: 16px;
    max-width: 440px;
}

.sideBarIcon {
    margin-right: 16px;
}

.subNavigationLeftContainer {
    border-width: 2px;
    border-color: #cfd8dc;
    border-left-style: base.$ptr-divider-type;
    padding-left: 16px;
    box-sizing: border-box;
}

.subnavigationLeftText {
    font-size: 20px;
    font-weight: 500;
    color: base.$ptr-font-color-dark;
}

.subNavSlash {
    color: base.$ptr-divider-color;
}

.subnavigationRight {
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.wellConfigMenu {
    box-sizing: border-box;
    padding-right: 16px;
    padding-left: 16px;
    border-width: 1px;
    border-color: base.$ptr-divider-color;
    border-right-style: base.$ptr-divider-type;
    border-left-style: base.$ptr-divider-type;
    display: flex;
    align-items: center;
    height: 100%;
}
