@use "../../../../../styles/base";

.containerRow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    height: 20px;
    width: 100%;
}

.textHeading {
    font-weight: 700;
    width: 101px;
}

.subtext {
    box-sizing: border-box;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.toolTipContainer {
    width: calc(100% - 101px);
}

.container {
    box-sizing: border-box;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
