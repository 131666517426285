@use "../../../../../../styles/base";
.container {
    width: 450px;
}
.queryLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 200px;
}
.queryError {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    margin: auto;
    color: base.$ptr-color-error;
    font-size: 24px;
    min-height: 200px;
    text-align: center;
}
.queryEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: base.$ptr-blue-gray-800;
    font-size: 24px;
    min-height: 200px;
    text-align: center;
}

.bodyBoldText {
    font-weight: 700;
}

.errorMessageContainer {
    margin-left: 4px;
}

.errorMessage {
    margin-bottom: 4px;
}
