@use "../../styles/base";

.container {
    width: 768px;
    min-width: 768px;
}

.bodyContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
}

.bodyLeft {
    height: 400px;
    width: 352px;
    border-radius: base.$ptr-radius-round;
    margin-right: 16px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.bodyRight {
    height: 400px;
    width: 352px;
    border-radius: base.$ptr-radius-round;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}
