//Global value must multiply or divisible by 8
$global-val: 16;

// -----------------------------
//Design Tokens
// -----------------------------

// -----------------------------
// Colors
// -----------------------------

//grey
$ptr-white: #ffffff !default;
$ptr-gray-50: #fafafa !default;
$ptr-gray-100: #f5f5f5 !default;
$ptr-gray-200: #eeeeee !default;
$ptr-gray-300: #e0e0e0 !default;
$ptr-gray-400: #bdbdbd !default;
$ptr-gray-500: #9e9e9e !default;
$ptr-gray-600: #757575 !default;
$ptr-gray-700: #616161 !default;
$ptr-gray-800: #424242 !default;
$ptr-gray-900: #212121 !default;
$ptr-black: #000000 !default;

//yellow
$ptr-yellow-50: #fffde7;
$ptr-yellow-100: #fff9c4;
$ptr-yellow-200: #fff59d;
$ptr-yellow-300: #fff176;
$ptr-yellow-400: #ffee58;
$ptr-yellow-500: #ffeb3b;
$ptr-yellow-600: #fdd835;
$ptr-yellow-700: #fbc02d;
$ptr-yellow-800: #f9a825;
$ptr-yellow-900: #f57f17;

//orange
$ptr-orange-50: #fff3e0;
$ptr-orange-100: #ffe0b2;
$ptr-orange-200: #ffcc80;
$ptr-orange-300: #ffb74d;
$ptr-orange-400: #ffa726;
$ptr-orange-500: #ff9800;
$ptr-orange-600: #fb8c00;
$ptr-orange-700: #f57c00;
$ptr-orange-800: #ef6c00;
$ptr-orange-900: #e65100;

//red
$ptr-red-50: #ffebee;
$ptr-red-100: #ffcdd2;
$ptr-red-200: #ef9a9a;
$ptr-red-300: #e57373;
$ptr-red-400: #ef5350;
$ptr-red-500: #f44336;
$ptr-red-600: #e53935;
$ptr-red-700: #d32f2f;
$ptr-red-800: #c62828;
$ptr-red-900: #b71c1c;

//blue
$ptr-blue-50: #e3f2fd;
$ptr-blue-100: #bbdefb;
$ptr-blue-200: #90caf9;
$ptr-blue-300: #64b5f6;
$ptr-blue-400: #42a5f5;
$ptr-blue-500: #2196f3;
$ptr-blue-600: #1e88e5;
$ptr-blue-700: #1976d2;
$ptr-blue-800: #1565c0;
$ptr-blue-900: #0d47a1;

//teal
$ptr-teal-50: #e0f2f1;
$ptr-teal-100: #b2dfdb;
$ptr-teal-200: #80cbc4;
$ptr-teal-300: #4db6ac;
$ptr-teal-400: #26a69a;
$ptr-teal-500: #009688;
$ptr-teal-600: #00897b;
$ptr-teal-700: #00796b;
$ptr-teal-800: #00695c;
$ptr-teal-900: #004d40;

//green
$ptr-green-50: #e8f5e9;
$ptr-green-100: #c8e6c9;
$ptr-green-200: #a5d6a7;
$ptr-green-300: #81c784;
$ptr-green-400: #66bb6a;
$ptr-green-500: #4caf50;
$ptr-green-600: #43a047;
$ptr-green-700: #388e3c;
$ptr-green-800: #2e7d32;
$ptr-green-900: #1b5e20;

//blue gray
$ptr-blue-gray-50: #eceff1;
$ptr-blue-gray-100: #cfd8dc;
$ptr-blue-gray-200: #b0bec5;
$ptr-blue-gray-300: #90a4ae;
$ptr-blue-gray-400: #78909c;
$ptr-blue-gray-500: #607d8b;
$ptr-blue-gray-600: #546e7a;
$ptr-blue-gray-700: #455a64;
$ptr-blue-gray-800: #37474f;
$ptr-blue-gray-900: #263238;

//opacity
$ptr-transparent: 0;
$ptr-transparent-rgba: rgba(0, 0, 0, 0);
$ptr-disabled-opacity: 0.5;
$ptr-disabled-color: $ptr-gray-300;

//Colors - Solid
$ptr-color-primary: $ptr-blue-600;
$ptr-color-secondary: $ptr-transparent-rgba;
$ptr-color-success: $ptr-green-500;
$ptr-color-error: $ptr-red-500;
$ptr-color-warning: $ptr-yellow-500;
$ptr-color-info: $ptr-gray-500;
$ptr-color-light: $ptr-gray-100;
$ptr-color-neutral: $ptr-gray-500;
$ptr-color-dark: $ptr-gray-800;

//Colors - Alert Background
$ptr-alert-error: $ptr-red-50;
$ptr-alert-secondary: $ptr-transparent-rgba;
$ptr-alert-primary: $ptr-blue-50;
$ptr-alert-success: $ptr-green-50;
$ptr-alert-warning: $ptr-yellow-50;
$ptr-alert-info: $ptr-gray-50;
$ptr-alert-light: $ptr-gray-500;
$ptr-alert-neutral: $ptr-gray-500;
$ptr-alert-dark: $ptr-gray-800;

//Colors - Alert Borders
$ptr-alert-error-border: $ptr-red-100;
$ptr-alert-secondary-border: $ptr-white;
$ptr-alert-primary-border: $ptr-blue-100;
$ptr-alert-success-border: $ptr-green-100;
$ptr-alert-warning-border: $ptr-yellow-100;
$ptr-alert-info-border: $ptr-gray-100;
$ptr-alert-light-border: $ptr-gray-100;
$ptr-alert-neutral-border: $ptr-gray-600;
$ptr-alert-dark-border: $ptr-gray-900;

// -----------------------------
// Color List
// -----------------------------

//Used with icons
$ptr-colors-list: (
    // "[name of class]"        $[name of alert color]
    "ptr-color--danger" $ptr-color-error,
    "ptr-color--secondary" $ptr-color-secondary,
    "ptr-color--primary" $ptr-color-primary,
    "ptr-color--success" $ptr-color-success,
    "ptr-color--warning" $ptr-color-warning,
    "ptr-color--info" $ptr-color-info,
    "ptr-color--light" $ptr-color-light,
    "ptr-color--neutral" $ptr-color-neutral,
    "ptr-color--dark" $ptr-color-dark
) !default;

//Used for banners
$ptr-colors-bg-alert-list: (
    // "[name of class]"        $[name of alert color]  $[name of alert border]
    "ptr-alert__bg--danger" $ptr-alert-error $ptr-alert-error-border,
    "ptr-alert__bg--secondary" $ptr-alert-secondary $ptr-alert-secondary-border,
    "ptr-alert__bg--primary" $ptr-alert-primary $ptr-alert-primary-border,
    "ptr-alert__bg--success" $ptr-alert-success $ptr-alert-success-border,
    "ptr-alert__bg--warning" $ptr-alert-warning $ptr-alert-warning-border,
    "ptr-alert__bg--info" $ptr-alert-info $ptr-alert-info-border,
    "ptr-alert__bg--light" $ptr-alert-light $ptr-alert-light-border,
    "ptr-alert__bg--neutral" $ptr-alert-neutral $ptr-alert-neutral-border,
    "ptr-alert__bg--dark" $ptr-alert-dark $ptr-alert-dark-border
) !default;

//Solid - List
$ptr-colors-solid: (
    // "[name of class]"        $[name of background]    $[name of font color]
    "primary" $ptr-color-primary $ptr-color-light,
    "secondary" $ptr-color-secondary $ptr-color-primary,
    "success" $ptr-color-success $ptr-color-light,
    "error" $ptr-color-error $ptr-color-light,
    "warning" $ptr-color-warning $ptr-color-light,
    "info" $ptr-color-info $ptr-color-light,
    "light" $ptr-color-light $ptr-color-dark,
    "neutral" $ptr-color-neutral $ptr-color-light,
    "dark" $ptr-color-dark $ptr-color-light
) !default;

// -----------------------------
// Containers
// -----------------------------

$ptr-container-border: 1px solid $ptr-gray-300;
$ptr-container-white: $ptr-white;
//$ptr-container-light:  ;

//rounded corners
$ptr-radius-round: 0.25rem;

//square corners
$ptr-radius-square: 0rem;

// -----------------------------
// Border
// -----------------------------

$ptr-border-width: 1px;
$ptr-border-style: solid;

//Button State
//$ptr-focus-border-color: ;
$ptr-focus-border-width: 3px;

// -----------------------------
// Dividers
// -----------------------------

$ptr-divider-width: 1px;
$ptr-divider-type: solid;
$ptr-divider-color: $ptr-blue-gray-100;
// -----------------------------
// Sizing
// -----------------------------

$ptr-sizing-025rem: 0.25rem; //4px
$ptr-sizing-050rem: 0.5rem; //8px
$ptr-sizing-075rem: 0.75rem; //12px
$ptr-sizing-100rem: 1rem; //16px
$ptr-sizing-125rem: 1.25rem; //20px
$ptr-sizing-150rem: 1.5rem; //24px
$ptr-sizing-175rem: 1.75rem; //28px
$ptr-sizing-200rem: 2rem; //32px
$ptr-sizing-225rem: 2.25rem; //36px
$ptr-sizing-250rem: 2.5rem; //40px
$ptr-sizing-300rem: 3rem; //48px
$ptr-sizing-350rem: 3.5rem; //56px
$ptr-sizing-400rem: 4rem; //64px
$ptr-sizing-450rem: 4.5rem; //72px
$ptr-sizing-500rem: 5rem; //80px
$ptr-sizing-550rem: 5.5rem; //88px
$ptr-sizing-600rem: 6rem; //96px
$ptr-sizing-650rem: 6.5rem; //104px

// -----------------------------
// Fonts
// -----------------------------

//font-family
$ptr-font-family: "Roboto", sans-serif;

//font-size
$ptr-font-size-1: $ptr-sizing-075rem; //12px
$ptr-font-size-2: $ptr-sizing-100rem; //16px
$ptr-font-size-3: $ptr-sizing-125rem; //20px
$ptr-font-size-4: $ptr-sizing-150rem; //24px
$ptr-font-size-5: $ptr-sizing-175rem; //28px
$ptr-font-size-6: $ptr-sizing-200rem; //32px
$ptr-font-size-7: $ptr-sizing-250rem; //40px
$ptr-font-size-8: $ptr-sizing-350rem; //56px
$ptr-font-size-9: $ptr-sizing-450rem; //72px
$ptr-font-size-10: $ptr-sizing-550rem; //88px
$ptr-font-size-11: $ptr-sizing-600rem; //96px

//font-line-height
$ptr-font-line-height-1: $ptr-sizing-125rem; //20px
$ptr-font-line-height-2: $ptr-sizing-150rem; //24px
$ptr-font-line-height-3: $ptr-sizing-175rem; //28px
$ptr-font-line-height-4: $ptr-sizing-200rem; //32px
$ptr-font-line-height-5: $ptr-sizing-225rem; //36px
$ptr-font-line-height-6: $ptr-sizing-250rem; //40px
$ptr-font-line-height-7: $ptr-sizing-300rem; //48px
$ptr-font-line-height-8: $ptr-sizing-400rem; //64px
$ptr-font-line-height-9: $ptr-sizing-500rem; //80px
$ptr-font-line-height-10: $ptr-sizing-600rem; //96px
$ptr-font-line-height-11: $ptr-sizing-650rem; //104px

//Font, Body, Button Label, Messages
$ptr-font-size-x-small: $ptr-font-size-1; //Input error messages
$ptr-font-size-small: 0.875rem; //Table Header and Data
$ptr-font-size-base: $ptr-font-size-2; //Default font size
$ptr-font-size-large: $ptr-font-size-3;

//font style
$ptr-font-style-i: italic; //font-style
$ptr-font-style-b: 500; //font-family
$ptr-font-style-u: underline; //underline
$ptr-font-style-s: line-through; //strike-through

//font color
$ptr-font-color-dark: $ptr-blue-gray-800;
$ptr-font-color-medium: $ptr-blue-gray-600;
$ptr-font-color-light: $ptr-white;

$ptr-font-color-link: $ptr-color-primary;
$ptr-font-color-error: $ptr-color-error;
$ptr-font-color-warning: $ptr-color-warning;
$ptr-font-color-success: $ptr-color-success;
$ptr-font-color-secondary: $ptr-color-primary;

//Font H tags
$ptr-font-h1: $ptr-font-size-7;
$ptr-font-h2: $ptr-font-size-6;
$ptr-font-h3: $ptr-font-size-5;
$ptr-font-h4: $ptr-font-size-4;
$ptr-font-h5: $ptr-font-size-3;
$ptr-font-h6: $ptr-font-size-2;

$ptr-font-h1-line-height: $ptr-font-line-height-7;
$ptr-font-h2-line-height: $ptr-font-line-height-6;
$ptr-font-h3-line-height: $ptr-font-line-height-5;
$ptr-font-h4-line-height: $ptr-font-line-height-4;
$ptr-font-h5-line-height: $ptr-font-line-height-3;
$ptr-font-h6-line-height: $ptr-font-line-height-2;

//Font Display Tags
$ptr-display-1: $ptr-font-size-11;
$ptr-display-2: $ptr-font-size-10;
$ptr-display-3: $ptr-font-size-9;
$ptr-display-4: $ptr-font-size-8;

$ptr-display-line-height-1: $ptr-font-line-height-11;
$ptr-display-line-height-2: $ptr-font-line-height-10;
$ptr-display-line-height-3: $ptr-font-line-height-9;
$ptr-display-line-height-4: $ptr-font-line-height-8;

// BOOTSTRAP ONLY ///
//Numbers for Bootstrap to complie properly
$ptr-font-line-height-small: $ptr-font-line-height-1;
$ptr-font-line-height-base: 1.5;
$ptr-font-line-height-large: $ptr-font-line-height-3;

$ptr-body-b-font: bold;
$ptr-body-i: italics;
$ptr-body-u: underline;
$ptr-body-s: strikethrough;

$ptr-font-weight-normal: normal;

// -----------------------------
// Overlay
// -----------------------------

$ptr-overlay-opactiy: 0.5;
$ptr-overlay-color: $ptr-black;

// -----------------------------
// Shadows
// -----------------------------

$ptr-shadows-sm: 0px 2px 4px rgba(0, 0, 0, 0.7);
$ptr-shadows-md: 0px 8px 16px rgba(0, 0, 0, 0.15);
$ptr-shadows-lg: 0px 16px 48px rgba(0, 0, 0, 0.17);
$ptr-shadows-inset: 0 1px 2px rgba($ptr-black, 0.075) inset;

// -----------------------------
// Space
// -----------------------------

$ptr-space-0: 0rem;
$ptr-space-s: 0.25rem;
$ptr-space-m: 0.5rem;
$ptr-space-l: 1rem;
$ptr-space-xl: 1.5rem;
$ptr-space-2xl: 2rem;
$ptr-space-3xl: 3rem;
$ptr-space-4xl: 4rem;
$ptr-space-5xl: 5rem;

// -----------------------------
// Z-index
// -----------------------------

$ptr-z-index-page-alert: 2000;
$ptr-z-index-nav: 99;

// -----------------------------
// -----------------------------
// -----------------------------
// Molecules
// -----------------------------
// -----------------------------
// -----------------------------

// -----------------------------
// Alert
// -----------------------------

// Alert - Global
$ptr-alert-text-padding-x: 0.5rem;

// Alert - Banner - Bootstrap
$ptr-alert-padding-y: 0.5rem;
$ptr-alert-padding-x: 0.5rem;
$ptr-alert-margin-bottom: 1rem;

$ptr-alert-radius: $ptr-radius-round;

$ptr-alert-link-font-weight: inherit;
$ptr-alert-border-width: $ptr-divider-width;

$ptr-alert-modal-radius: 0;

$ptr-alert-font-size: $ptr-font-size-base;
$ptr-alert-line-height: $ptr-font-line-height-base;

// Alert - Modal
//Bottom of the Modal
$ptr-alert-modal-margin-x: -1.5rem;
$ptr-alert-modal-margin-y: -1.5rem;

$ptr-alert-modal-padding-x: 0.5rem;
$ptr-alert-modal-padding-y: 0.5rem;

$ptr-alert-modal-text-margin-x: 0.5rem;

// Alert - Page
//Bottom of the Page
$ptr-alert-page-margin-x: -1.5rem;

$ptr-alert-page-padding-x: 1rem;
$ptr-alert-page-padding-y: 1rem;

// -----------------------------
// Badge
// -----------------------------

$ptr-badge-padding-y: 0;
$ptr-badge-padding-x: $ptr-sizing-050rem;

$ptr-badge-color: $ptr-font-color-light;
$ptr-badge-font-size: $ptr-font-size-x-small;
$ptr-badge-font-line-height: 1.75;

$ptr-badge-pill-border-radius: 10rem;

// -----------------------------
// Button
// -----------------------------
//Basic Specs

//Button - Font
$ptr-button-font-size: $ptr-font-size-base;
$ptr-button-line-height: $ptr-font-line-height-base;
$ptr-button-font-weight: $ptr-font-weight-normal;

//Button - Size
$ptr-button-height: 34px;

$ptr-button-padding-y: 0.25rem;
$ptr-button-padding-x: 0.5rem;

$ptr-button-radius: $ptr-radius-round;
$ptr-button-border-style: $ptr-border-style;
$ptr-button-border-width: $ptr-border-width;

$ptr-button-focus-box-shadow: 0 0 0 4px $ptr-blue-200;

$ptr-button-disabled-opacity: $ptr-disabled-opacity;

// Button-Icon
$ptr-button-icon-width: 34px;

//Primary Color
$ptr-button-color: $ptr-white;
$ptr-button-background-color: $ptr-color-primary;

$ptr-button-active-color: $ptr-white;
$ptr-button-active-background-color: $ptr-blue-800;
$ptr-button-active-border-color: $ptr-blue-900;

$ptr-button-focus-color: $ptr-white;
$ptr-button-focus-background-color: $ptr-blue-800;
$ptr-button-focus-border-color: $ptr-blue-900;

$ptr-button-hover-color: $ptr-white;
$ptr-button-hover-background-color: $ptr-blue-700;
$ptr-button-hover-border-color: $ptr-blue-700;

//Secondary Color
$ptr-button-secondary-color: $ptr-color-primary;
$ptr-button-secondary-border-color: $ptr-transparent-rgba;
$ptr-button-secondary-background-color: $ptr-transparent-rgba;

$ptr-button-secondary-active-color: $ptr-blue-900;
$ptr-button-secondary-active-background-color: $ptr-transparent-rgba;
$ptr-button-secondary-active-box-shadow: $ptr-button-focus-box-shadow;
$ptr-button-secondary-active-border-color: $ptr-transparent-rgba;

$ptr-button-secondary-focus-color: $ptr-blue-900;
$ptr-button-secondary-focus-background-color: $ptr-gray-200;
$ptr-button-secondary-focus-border-color: $ptr-gray-200;

$ptr-button-secondary-hover-color: $ptr-blue-700;
$ptr-button-secondary-hover-background-color: $ptr-gray-200;
$ptr-button-secondary-hover-border-color: $ptr-transparent-rgba;

//Tertiary Color
$ptr-button-tertiary-color: $ptr-gray-800;
$ptr-button-tertiary-border-color: $ptr-transparent-rgba;
$ptr-button-tertiary-background-color: $ptr-transparent-rgba;

$ptr-button-tertiary-active-color: $ptr-gray-600;
$ptr-button-tertiary-active-background-color: $ptr-transparent;

$ptr-button-tertiary-focus-color: $ptr-gray-600;
$ptr-button-tertiary-focus-background-color: $ptr-transparent-rgba;
$ptr-button-tertiary-focus-border-color: $ptr-transparent-rgba;

$ptr-button-tertiary-hover-color: $ptr-gray-600;
$ptr-button-tertiary-hover-background-color: $ptr-transparent-rgba;

// Danger Color
$ptr-button-danger-color: $ptr-white;
$ptr-button-danger-background-color: $ptr-red-600;

$ptr-button-danger-active-color: $ptr-white;
$ptr-button-danger-active-background-color: $ptr-red-900;
$ptr-button-danger-active-border-color: $ptr-red-900;

$ptr-button-danger-focus-color: $ptr-white;
$ptr-button-danger-focus-background-color: $ptr-red-800;
$ptr-button-danger-focus-border-color: $ptr-red-900;

$ptr-button-danger-hover-color: $ptr-white;
$ptr-button-danger-hover-background-color: $ptr-red-700;
$ptr-button-danger-hover-border-color: $ptr-red-800;

//Link button
$ptr-button-link-color: $ptr-color-primary;
$ptr-button-link-hover-color: $ptr-blue-700;
$ptr-button-link-disabled-color: $ptr-color-primary;

// -----------------------------
// Cards
// -----------------------------
$ptr-card-padding-y: 1.5rem;
$ptr-card-padding-x: 1.5rem;

$ptr-card-height: null;
$ptr-card-color: null;
$ptr-card-bg: $ptr-container-white;

$ptr-card-first-child-margin-y: 1.5rem;
$ptr-card-margin-y: 1rem;
$ptr-card-last-child-margin-y: 1.5rem;
$ptr-card-margin-x: 1.5rem;

$ptr-card-title-margin: 0 0 1rem 0;

$ptr-card-border-width: $ptr-divider-width;
$ptr-card-border-type: $ptr-divider-type;
$ptr-card-border-color: $ptr-divider-color;

$ptr-card-border-radius: $ptr-radius-round;

$ptr-card-box-shadow: null;

// Card Header and Footer

$ptr-card-cap-padding-y: 0.5rem;
$ptr-card-cap-padding-x: 1.5rem;
$ptr-card-cap-bg: $ptr-gray-100;
$ptr-card-cap-color: null;

$ptr-card-header-padding-y: 0.5rem;
$ptr-card-header-padding-x: 1rem;

$ptr-card-footer-padding-y: 0.5rem;
$ptr-card-footer-padding-x: 1.5rem;

// Card - Inner

$ptr-inner-card-padding-y: 1rem;
$ptr-inner-card-padding-x: 0.5rem;

//Child card with a child card inside it.
$ptr-card-parent-bg: $ptr-gray-100;

// Card within a card or white container. Not a parent to cards
$ptr-inner-card-bg: $ptr-gray-100;

// -----------------------------
// Close Button
// -----------------------------
$ptr-btn-close-width: $ptr-sizing-100rem;
$ptr-btn-close-height: $ptr-sizing-100rem;
$ptr-btn-close-padding-x: 0;
$ptr-btn-close-padding-y: 0;
$ptr-btn-close-color: $ptr-font-color-dark;

// -----------------------------
// Input Fields
// -----------------------------
//form text
$ptr-form-text-margin-top: 0.25rem;
$ptr-form-text-font-size: $ptr-font-size-x-small;
$ptr-form-text-font-style: null !default;
$ptr-form-text-font-weight: null !default;
$ptr-form-text-color: $ptr-color-neutral;

//form - input labels
$ptr-input-label-font-color: $ptr-font-color-dark;
$ptr-input-label-font-size: $ptr-font-size-base;
$ptr-input-label-line-height: $ptr-font-line-height-base;
$ptr-input-label-margin-bottom: 0.5rem;

//form - input containers
$ptr-input-padding-y: 0.25rem;
$ptr-input-padding-x: 0.75rem;
$ptr-input-font-family: $ptr-font-family;
$ptr-input-font-size: $ptr-font-size-base;
$ptr-input-font-weight: default;
$ptr-input-line-height: $ptr-font-line-height-base;

$ptr-input-background-color: $ptr-white;
$ptr-input-background-disabled-color: $ptr-disabled-color;
$input-disabled-border-color: null;

$ptr-input-text-color: $ptr-font-color-dark;
$ptr-input-border-width: $ptr-divider-width;
$ptr-input-border-type: $ptr-divider-type;
$ptr-input-border-color: $ptr-divider-color;
$ptr-input-box-shadow: $ptr-shadows-inset;

$ptr-input-border-radius: $ptr-radius-round;
$ptr-input-border-radius-sm: $ptr-radius-round;
$ptr-input-border-radius-lg: $ptr-radius-round;

//form - input containers - focus
$ptr-input-focus-bg: $ptr-white;
$ptr-input-focus-border-color: $ptr-blue-200;
$ptr-input-focus-color: $ptr-font-color-dark;
$ptr-input-focus-height: 88px;
$ptr-input-focus-width: 0.25rem;
$ptr-input-focus-box-shadow: 0 0 0 4px $ptr-blue-200;

//form - input containers - placeholder
$ptr-input-placeholder-color: $ptr-gray-600;
$ptr-input-plaintext-color: $ptr-font-color-dark;
$ptr-input-plaintext-style: $ptr-font-style-b;

//form - input read only containers
$ptr-input-read-only-font-color: $ptr-font-color-dark;
$ptr-input-read-only-font-size: $ptr-font-size-base;
$ptr-input-read-only-line-height: $ptr-font-line-height-base;
$ptr-input-read-only-font-family: $ptr-font-family;
$ptr-input-read-only-height: 64px;
$ptr-input-read-only-padding-y: 0.25rem;
$ptr-input-read-only-weight: 700;

//form - validation
$ptr-input-validation-font-size: $ptr-font-size-x-small;
$ptr-input-validation-line-height: $ptr-font-line-height-small;
$ptr-input-validation-margin-top: 0.25rem;

//form - Group, Append, Prepend
$ptr-input-group-addon-color: $ptr-font-color-dark !default;
$ptr-input-group-addon-bg: $ptr-gray-200 !default;
$ptr-input-group-addon-border-color: $ptr-divider-color !default;
$ptr-form-group-margin-bottom: 1rem !default;

//form - selection control
$ptr-form-selection-width: 1rem;
$ptr-form-selection-margin-bottom: 1.25rem;

$ptr-form-selection-background: $ptr-white;
$ptr-form-selection-border: 1px solid $ptr-gray-700;
$ptr-form-checkbox-border-radius: 0.125rem;
$ptr-form-radio-border-radius: 50%;
$ptr-form-input-focus-border: $ptr-color-primary;
$ptr-form-input-focus-box-shadow: rgb(0, 0, 0);

$ptr-form-selection-color: $ptr-white;
$ptr-form-selection-background-color: $ptr-color-primary;
$ptr-form-selection-border-color: $ptr-gray-700;

$ptr-form-selection-indeterminate-color: $ptr-white;
$ptr-form-selection-indeterminate-background-color: $ptr-color-primary;
$ptr-form-selection-indeterminate-border-color: $ptr-gray-700;

$ptr-form-check-input-disabled-opacity: $ptr-disabled-opacity;
$ptr-form-check-label-disabled-opacity: $ptr-disabled-opacity;
$ptr-form-check-btn-check-disabled-opacity: $ptr-disabled-opacity;

$ptr-form-selection-margin-end: $ptr-sizing-100rem;

//dropdown
$ptr-dropdown-min-width: 10rem;
$ptr-dropdown-padding-x: 0rem;
$ptr-dropdown-padding-y: $ptr-sizing-025rem;
$ptr-dropdown-spacer: $ptr-sizing-050rem;
$ptr-dropdown-font-size: $ptr-font-size-base;
$ptr-dropdown-color: $ptr-white;
$ptr-dropdown-background: $ptr-white;

$ptr-dropdown-border-radius: $ptr-radius-round;
$ptr-dropdown-border-width: $ptr-divider-width;

$ptr-dropdown-divider-background: $ptr-divider-color;
$ptr-dropdown-divider-margin-y: $ptr-sizing-050rem;
$ptr-dropdown-box-shadow: $ptr-shadows-md;

$ptr-dropdown-link-color: $ptr-font-color-dark;
$ptr-dropdown-link-hover-color: $ptr-font-color-dark;
$ptr-dropdown-link-hover-bg: $ptr-color-light;

$ptr-dropdown-danger-bg-color: $ptr-red-50;
$ptr-dropdown-danger-color: $ptr-font-color-dark;
$ptr-dropdown-danger-hover-bg: $ptr-red-100;
$ptr-dropdown-danger-hover-color: $ptr-font-color-dark;

$ptr-dropdown-link-active-color: $ptr-font-color-light;
$ptr-dropdown-link-active-bg: $ptr-color-primary;

$ptr-dropdown-link-disabled-color: $ptr-disabled-color;

$ptr-dropdown-item-padding-y: $ptr-sizing-025rem;
$ptr-dropdown-item-padding-x: $ptr-sizing-150rem;

$ptr-dropdown-header-color: $ptr-gray-600;

// -----------------------------
// Form - Selection Controls
// -----------------------------
$ptr-selection-check-input-width: $ptr-sizing-100rem;
$ptr-selection-check-margin-bottom: $ptr-sizing-125rem;

$ptr-selection-check-input-background: $ptr-white;
$ptr-selection-check-input-border: 1px solid $ptr-gray-700;
$ptr-selection-check-input-border-radius: 0.125rem;
$ptr-selection-check-radio-border-radius: 50%;
$ptr-selection-check-input-focus-border: $ptr-color-primary;
$ptr-selection-check-input-focus-box-shadow: rgb(0, 0, 0);

$ptr-selection-check-input-checked-color: $ptr-white;
$ptr-selection-check-input-checked-background-color: $ptr-color-primary;
$ptr-selection-check-input-checked-border-color: $ptr-color-primary;

$ptr-selection-check-input-indeterminate-color: $ptr-white;
$ptr-selection-check-input-indeterminate-background-color: $ptr-color-primary;
$ptr-selection-check-input-indeterminate-border-color: $ptr-color-primary;

$ptr-selection-check-input-disabled-opacity: $ptr-disabled-opacity;
$ptr-selection-check-label-disabled-opacity: $ptr-disabled-opacity;
$ptr-selection-check-btn-check-disabled-opacity: $ptr-disabled-opacity;

$ptr-selection-check-inline-margin-end: $ptr-sizing-100rem;

$ptr-selection-switch-background: $ptr-gray-500;
$ptr-selection-switch-container-width: 1.75em;
$ptr-selection-border-radius: 1.75em;
$ptr-selection-focus-background-color: $ptr-gray-500;
$ptr-selection-checked-color: $ptr-white;

// -----------------------------
// List Group
// -----------------------------
$ptr-list-group-color: $ptr-gray-900;
$ptr-list-group-bg: $ptr-white;
$ptr-list-group-border-color: $ptr-divider-color;
$ptr-list-group-border-width: $ptr-divider-width;
$ptr-list-group-border-radius: $ptr-radius-round;

$ptr-list-group-text-top-color: $ptr-font-color-dark;
$ptr-list-group-text-bottom-color: $ptr-font-color-medium;

$ptr-list-group-item-padding-y: $ptr-sizing-050rem;
$ptr-list-group-item-padding-x: $ptr-sizing-100rem;
$ptr-list-group-item-bg-scale: 0%;
$ptr-list-group-item-color-scale: 0%;

$ptr-list-group-hover-bg: $ptr-gray-100;
$ptr-list-group-active-color: $ptr-white;
$ptr-list-group-active-bg: $ptr-color-primary;
$ptr-list-group-active-border-color: $ptr-color-primary;

$ptr-list-group-disabled-color: $ptr-font-color-dark;
$ptr-list-group-disabled-bg: $ptr-white;

$ptr-list-group-action-color: $ptr-font-color-dark;
$ptr-list-group-action-hover-color: $ptr-font-color-dark;

$ptr-list-group-action-active-color: $ptr-white;
$ptr-list-group-action-active-bg: $ptr-color-primary;

// -----------------------------
// Modals
// -----------------------------
$ptr-modal-padding: 1.5rem;

$ptr-modal-footer-margin: 1.5rem;

$ptr-modal-body-margin: 1.5rem;

$ptr-modal-title-line-height: 1.5rem;
$ptr-modal-title-margin-bottom: 0px;

$ptr-modal-content-color: null;
$ptr-modal-content-background: $ptr-white;
$ptr-modal-content-border-color: $ptr-divider-color;
$ptr-modal-content-border-width: $ptr-divider-width;
$ptr-modal-content-border-radius: $ptr-radius-round;

$ptr-modal-content-box-shadow-xs: $ptr-shadows-sm;
$ptr-modal-content-box-shadow: $ptr-shadows-md;

$ptr-modal-header-border-color: $ptr-divider-color;
$ptr-modal-footer-border-color: $ptr-divider-color;
$ptr-modal-header-border-width: $ptr-divider-width;
$ptr-modal-footer-border-width: $ptr-divider-width;
$ptr-modal-header-padding-y: 1.5rem;
$ptr-modal-header-padding-x: 1.5rem;

$ptr-modal-sm: 300px;
$ptr-modal-md: 500px;
$ptr-modal-lg: 768px;
$ptr-modal-xl: 1140px;

// -----------------------------
// Nav
// -----------------------------

$ptr-nav-display: flex;
$ptr-nav-list-style: none;
$ptr-nav-margin: 0 0 1rem 0;
$ptr-nav-padding: 0 0 0 0;

$ptr-nav-item-margin: 0 0.5rem 0 0.5rem;
$ptr-nav-item-margin-first-child: 0 0.5rem 0 0;
$ptr-nav-item-margin-last-child: 0 0 0 0.5rem;

$ptr-nav-link-padding-y: $ptr-sizing-050rem;
$ptr-nav-link-padding-x: $ptr-sizing-100rem;
$ptr-nav-link-font-size: null;
$ptr-nav-link-font-weight: null;
$ptr-nav-link-color: $ptr-color-primary;
$ptr-nav-link-hover-color: $ptr-font-color-dark;
$ptr-nav-link-outline: 1px solid $ptr-color-primary;
$ptr-nav-link-text-align: center;

$ptr-nav-link-disabled-color: $ptr-font-color-medium;
$ptr-nav-link-disabled-background-color: $ptr-gray-100;
$ptr-nav-link-disabled-outline: solid 1px $ptr-gray-300;
$ptr-nav-link-disabled-radius: $ptr-radius-round;
$ptr-nav-link-disabled-opacity: 1;

$ptr-nav-tabs-border-color: $ptr-divider-color;
$ptr-nav-tabs-border-width: $ptr-divider-width;
$ptr-nav-tabs-border-radius: $ptr-radius-round;
$ptr-nav-tabs-link-hover-border-color: $ptr-divider-color $ptr-divider-color
    $ptr-divider-color;
$ptr-nav-tabs-link-active-color: $ptr-font-color-dark;
$ptr-nav-tabs-link-active-background: $ptr-white;
$ptr-nav-tabs-link-active-border-color: $ptr-gray-300 $ptr-gray-300 $ptr-white !default;

$ptr-nav-pills-link-color: $ptr-color-primary;
$ptr-nav-pills-border-radius: $ptr-radius-round;
$ptr-nav-pills-link-active-color: $ptr-font-color-light !important;
$ptr-nav-pills-link-active-background: $ptr-color-primary;

// -----------------------------
// Navbar
// -----------------------------
$ptr-navbar-padding-y: 0.5rem;
$ptr-navbar-padding-x: 1rem;

$ptr-navbar-height: 64px;
$ptr-navbar-width: 100%;
$ptr-navbar-min-width: 768px;

$ptr-navbar-button-padding-x: $ptr-sizing-100rem;

$ptr-navbar-brand-font-size: $ptr-font-size-base;

$ptr-navbar-brand-margin-end: $ptr-sizing-100rem;

$ptr-navbar-button-radius: $ptr-radius-round;
$ptr-navbar-button-color: $ptr-font-color-light;
$ptr-navbar-button-border-width: $ptr-border-width;
$ptr-navbar-button-border-style: $ptr-border-style;
$ptr-navbar-button-border-color: $ptr-transparent-rgba;

$ptr-navbar-button-hover-color: $ptr-font-color-light;
$ptr-navbar-button-hover-border-color: $ptr-color-light;

$ptr-navbar-button-active-color: $ptr-font-color-dark;
$ptr-navbar-button-disabled-color: $ptr-font-color-light;
$ptr-navbar-toggler-border-color: $ptr-font-color-light;

$ptr-navbar-background: $ptr-transparent;
$ptr-navbar-hover-background: $ptr-transparent;
$ptr-navbar-active-background: $ptr-font-color-light;
$ptr-navbar-disabled-background: $ptr-transparent;

$ptr-navbar-hover-border: $ptr-transparent;

// -----------------------------
// Pagination
// -----------------------------
$ptr-pagination-padding-y: $ptr-sizing-025rem;
$ptr-pagination-padding-x: $ptr-sizing-075rem;
$ptr-pagination-padding-y-sm: $ptr-sizing-025rem;
$ptr-pagination-padding-x-sm: $ptr-sizing-075rem;
$ptr-pagination-padding-y-lg: $ptr-sizing-025rem;
$ptr-pagination-padding-x-lg: $ptr-sizing-075rem;

$ptr-pagination-color: $ptr-font-color-link;
$ptr-pagination-background: $ptr-white;
$ptr-pagination-border-width: $ptr-divider-width;
$ptr-pagination-border-radius: $ptr-radius-round;
$ptr-pagination-border-color: $ptr-divider-color;

// -----------------------------
// Sidebar
// -----------------------------
$ptr-sidebar-leftside-background-color: $ptr-white;
$ptr-sidebar-leftside-right-border: $ptr-divider-width $ptr-divider-type
    $ptr-divider-color;

$ptr-sidebar-leftside-height: auto;
$ptr-sidebar-leftside-max-width: 360px;
$ptr-sidebar-leftside-min-width: 240px;

// -----------------------------
// Sidebar
// -----------------------------
$ptr-subnav-padding-top: 1rem;
$ptr-subnav-padding-bottom: 1rem;
$ptr-subnav-padding-left: 1.5rem;
$ptr-subnav-padding-right: 1.5rem;

$ptr-subnav-height: 68px;
$ptr-subnav-width: 100%;

$ptr-subnav-background-color: $ptr-white;
$ptr-subnav-border-bottom: $ptr-divider-width $ptr-divider-type
    $ptr-divider-color;

// -----------------------------
// Slider
// -----------------------------

$ptr-slider-background-color: $ptr-white;

$ptr-slider-border-color: $ptr-divider-width $ptr-divider-type
    $ptr-divider-color;

$ptr-slider-width-small: 480px;
$ptr-slider-width-large: 768px;

$ptr-slider-header-padding-y: 1rem;
$ptr-slider-header-padding-x: 1.5rem;
$ptr-slider-header-height: 74px;

$ptr-slider-body-padding-y: 1.5rem;
$ptr-slider-body-padding-x: 1.5rem;

// -----------------------------
// Tables
// -----------------------------
$ptr-table-cell-padding-y: 0px;
$ptr-table-cell-padding-x: $ptr-sizing-075rem;

$ptr-table-header-padding-y: $ptr-sizing-075rem;
$ptr-table-header-padding-x: $ptr-sizing-075rem;

$ptr-table-cell-font-size: $ptr-font-size-small;

$ptr-table-header-font-size: $ptr-font-size-small;
$ptr-table-header-font-weight: $ptr-font-style-b;

$ptr-table-font-color: $ptr-font-color-dark;
$ptr-table-background-color: $ptr-transparent;
$ptr-table-accent-background: $ptr-transparent;

$ptr-table-border-width: $ptr-divider-width;
$ptr-table-border-color: $ptr-divider-color;

$ptr-table-striped-color: $ptr-font-color-dark;

$ptr-table-active-color: $ptr-font-color-dark;

$ptr-table-hover-color: $ptr-font-color-dark;

// -----------------------------
// Tooltips
// -----------------------------
$ptr-tooltip-font-size: $ptr-font-size-x-small;
$ptr-tooltip-max-width: 200px;
$ptr-tooltip-color: $ptr-color-light;
$ptr-tooltip-background: $ptr-gray-800;
$ptr-tooltip-border-radius: $ptr-radius-round;
$ptr-tooltip-opacity: 1;
$ptr-tooltip-padding-y: $ptr-sizing-100rem;
$ptr-tooltip-padding-x: $ptr-sizing-100rem;
$ptr-tooltip-margin: 0;

$ptr-tooltip-arrow-width: $ptr-sizing-150rem;
$ptr-tooltip-arrow-height: $ptr-sizing-075rem;
$ptr-tooltip-arrow-color: $ptr-gray-800;
