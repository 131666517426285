@use "../../../styles/base";

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 24px;
    align-items: center;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    font-size: 20px;
}

.title {
    box-sizing: border-box;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: base.$ptr-blue-gray-800;
}

.subTitle {
    font-weight: 500;
    font-size: 16px;
    color: base.$ptr-blue-gray-600;
}

.closeIcon {
    width: 24px;
    height: 24px;
    font-weight: 900;
    pointer-events: all;
    cursor: pointer;
    color: #455a64;
}
