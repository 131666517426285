@use "../../../../styles/base";
@use "../../../../styles/breakpoint";

.container {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: #fff;
}

.pageSizeSelectorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.arrowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrowButton {
    @include breakpoint.sm {
        padding-left: 4px;
        padding-right: 4px;
    }
}

.textContainer {
    padding-left: 12px;
    padding-right: 12px;
    font-family: "Roboto";
    font-size: 16px;
    color: #000000;

    @include breakpoint.sm {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.pageSelector {
    width: 70px;
}

.pageSelectorLabel {
    min-width: 0px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
