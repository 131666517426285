@use "../../../styles/base";

.container {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: base.$ptr-font-color-dark;
    background-color: base.$ptr-gray-100;
}

.title {
    box-sizing: border-box;
    background-color: base.$ptr-gray-200;
    border-width: 2px;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    padding: 8px 16px;
    width: 100%;
    height: 40px;
}

.inactiveStyling {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;

    background-color: base.$ptr-white;
}

.inactiveStyling:hover {
    cursor: pointer;
}

.activeStyling {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    background-color: base.$ptr-color-primary;
    color: base.$ptr-white;
}

.activeStyling:hover {
    cursor: pointer;
}

.arrayContainer {
    overflow-y: auto;
    height: 360px;
    width: 100%;
    box-sizing: border-box;
}

.apiNumber {
    padding-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
