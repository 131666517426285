@use "../../../styles/base";

.container {
    box-sizing: border-box;
    padding-bottom: 65px;
    padding-left: #{base.$global-val * 1.5}px;
    padding-right: #{base.$global-val * 1.5}px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
}

.wrapper {
    box-sizing: border-box;
    padding: #{base.$global-val}px;
    width: 452px;
    height: 248px;
    background: base.$ptr-gray-50;
    margin-top: #{base.$global-val}px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    border-radius: base.$ptr-radius-round;
}

.title {
    padding-bottom: 16px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    font-weight: 500;
    font-size: 16px;
    color: base.$ptr-black;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-top-style: base.$ptr-divider-type;
    padding-top: #{base.$global-val * 1.5}px;
}

.emptyNavigation {
    box-sizing: border-box;
    height: 68px;
    width: 101px;
    display: flex;
    flex-direction: column;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    border-radius: base.$ptr-radius-round;
    background-color: base.$ptr-gray-50;
}

.navControlRunModeText {
    padding-bottom: 4px;
}

.flexContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
    height: 180px;
    padding-top: 16px;
}

.flexLeft {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
}

.dataContainer {
    display: flex;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
}

.dataLabel {
    min-width: 64px;
    text-align: left;
    margin-right: 5px;
}

.dataValue {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dataValueEmail {
    font-weight: 700;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    white-space: nowrap;
}

.description {
    width: 50%;
    height: 100%;
    overflow-y: auto;
    overflow-wrap: break-word;
    padding-left: 8px;
}
