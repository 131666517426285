@use "../../../styles/base";

.bodyContainer {
    padding: 24px;
}

.boldHeading {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #37474f;
}

.errorLink {
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
}

.errorMessage {
    width: 440px;
}
