.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.silenceContainer {
    margin-top: 16px;
}

.initalSilenceRow {
    margin-bottom: 8px;
    font-weight: 500;
}

.silenceTitleText {
    font-weight: 700;
    font-size: 16px;
}

.silenceLabel {
    width: 200px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.silenceValueInput {
    width: 86px;
}

.silenceUnitInput {
    width: 86px;
    margin-left: 8px;
}

.silenceBodyText {
    font-weight: 400;
    font-size: 16px;
    color: #838383;
}

.silenceUntilRow {
    margin-top: 8px;
}

.removeSilence {
    margin-top: 8px;
    margin-left: -8px;
}

.marginRight8 {
    margin-right: 8px;
}

.marginRight4 {
    margin-right: 4px;
}

.fai {
    width: 16px;
    height: 16px;
    color: #00aaff;
}
