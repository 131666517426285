.receiveNotifications {
    color: #1e88e5;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
}

.receiveNotificationsDisabled {
    color: #d40000;
    font-weight: 400;
    font-size: 16px;
}

.subscriptionCellContainer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.silencedIcon {
    margin-right: 8px;
    color: #546e7a;
    width: 20px;
    height: 20px;
}

.silencedTriggerValue {
    display: inline;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dateTimeDisplay {
    margin-left: 4px;
}

.popoverTriggerStyles {
    width: 100%;
}
