@use "../../../styles/base";

.container {
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-top-style: base.$ptr-divider-type;
    border-bottom-style: base.$ptr-divider-type;
}

.runModeOn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    color: #6c757d;
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
    height: 205px;
}

.runModeStopped {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    color: #6c757d;
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
    height: 205px;
}
