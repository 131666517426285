.container {
    width: 500px;
}

.deleteContainer {
    box-sizing: border-box;
    margin: 16px;
}

.deleteButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
