@use "../../../styles/base";

.tableLink {
    color: base.$ptr-font-color-link;
    text-decoration: none;
}

.tableLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 85px;

    @media (max-width: 1160px) {
        position: absolute;
        left: 50%;
        top: 300px;
        box-sizing: border-box;
        width: auto;
        margin-top: 0px;
    }
}

.tableError {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: base.$ptr-color-error;
}

.emptyState {
    width: 500px;
    margin: auto;
    text-align: center;
    margin-top: 40px;
}
