@use "../../../styles/base";
@use "../../../styles/breakpoint";

.pageContainer {
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 0px 24px;
    background-color: #eceff1;

    @include breakpoint.sm {
        padding: 0;
    }

    height: calc(100% - 64px);
}

.OuterTableWrapper {
    box-sizing: border-box;
    background-color: #fff;
    max-height: 940px;
    border: base.$ptr-divider-width base.$ptr-divider-type #cfd8dc;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.WellIndexOuterTableWrapper {
    width: 100%;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
}

.AdminOuterTableWrapper {
    width: 100%;
    max-width: 1117px;
    max-height: 858px;
    margin-left: auto;
    margin-right: auto;
}

.overflowWrapper {
    box-sizing: border-box;
    height: 100%;
    overflow-x: auto;
}

/** base classes for table*/
table {
    border-collapse: collapse;
}

.tableWrapper {
    box-sizing: border-box;
    width: 100%;
}

thead {
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
}

tr {
    display: flex;
    width: 100%;
}
