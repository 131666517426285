@use "../../../../../../styles/base";
@use "../../../../../../styles/breakpoint";

.outer {
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.outerClosed {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.mobileButton {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
}

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    @include breakpoint.md {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @include breakpoint.sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.containerLeft {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    @include breakpoint.md {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @include breakpoint.sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.containerRight {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.filter {
    margin-right: 8px;
    @include breakpoint.md {
        margin-bottom: 8px;
        margin-right: 0px;
    }
    @include breakpoint.sm {
        margin-bottom: 8px;
        margin-right: 0px;
    }
}
