.DropdownMenuItem {
    position: relative;
    cursor: pointer;
    height: 32px;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DropdownMenuItem[data-highlighted] {
    background-color: #f5f5f5;
    outline: none;
}
