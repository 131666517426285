@use "../../../styles/base";

// wrapper

.input-horizontal-flex-wrapper {
    display: flex;
}

.input-and-label-wrapper {
    display: flex;
}

// base input class
.base-input-class {
    box-sizing: border-box;
    outline: none;
    padding: base.$ptr-input-padding-y base.$ptr-input-padding-x;
    border-width: base.$ptr-input-border-width;
    border-style: base.$ptr-input-border-type;
    border-color: base.$ptr-input-border-color;
    border-radius: base.$ptr-radius-round;
    background-color: base.$ptr-white;
    font-weight: 400;
    font-size: base.$ptr-font-size-base;
    color: base.$ptr-input-text-color;
    margin-bottom: 4px;
    height: 32px;
    font-family: "Roboto";
}

.base-input-class:focus {
    border-color: base.$ptr-blue-300;
    box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}

.base-input-class:disabled {
    background-color: base.$ptr-blue-gray-50;
    color: #111;
    opacity: 0.9;
}

// specialized input styling
.input-success {
    border-color: base.$ptr-color-success;
}

.input-danger {
    border-color: base.$ptr-color-error;
}

.input-phone-number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-phone-number-prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: base.$ptr-input-padding-y base.$ptr-input-padding-x;
    border-width: base.$ptr-input-border-width;
    border-style: base.$ptr-input-border-type;
    border-color: base.$ptr-input-border-color;
    border-top-left-radius: base.$ptr-radius-round;
    border-bottom-left-radius: base.$ptr-radius-round;
    background-color: base.$ptr-gray-200;
    margin-bottom: 4px;
    font-weight: bold;
}

.input-readOnly {
    border: none;
    font-weight: 700;
}

.input-noMessage {
    margin-bottom: 0px;
}

// nested label right
.nestedLabelRight {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 32px;
    color: base.$ptr-input-text-color;
    background-color: base.$ptr-gray-200;
    font-weight: 400;
    font-size: 16px;
    border: solid 1px #cfd8da;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 4px 8px;
    color: black;
}

// message classes
.messageClass {
    font-weight: 400;
    font-size: base.$ptr-font-size-x-small;
    color: base.$ptr-font-color-medium;
    min-height: 20px;
}

.message-danger {
    color: base.$ptr-color-error;
}

.message-readOnly {
    font-weight: 700;
}

.message-noMessage {
    min-height: 0px;
}

// label classes
.horizontal-label-class {
    margin-right: 8px;
    display: inline-block;
    min-width: 124px;
    height: 24px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: base.$ptr-input-label-font-size;
    color: base.$ptr-input-label-font-color;
    position: relative;
    top: 6px;
}

.vertical-label-class {
    display: block;
    margin-bottom: base.$ptr-input-label-margin-bottom;
    min-width: 124px;
    height: 24px;
    font-weight: 400;
    font-size: base.$ptr-input-label-font-size;
    color: base.$ptr-input-label-font-color;
}

// input[ type = 'text' || type = 'number'] classes
.horizontal-input-class {
    width: 100%;
    height: 32px;
}

.vertical-input-class {
    width: 100%;
    height: 32px;
}

// textArea classes
.horizontal-textArea-class {
    width: 255px;
    height: 80px;
}

.vertical-textArea-class {
    width: 123px;
    height: 80px;
}

// select classes
.horizontal-select-class {
    width: 178px;
    height: 32px;
}

.vertical-select-class {
    width: 100%;
    height: 32px;
}

.horizontal-time-class {
    min-width: 140px;
}

.vertical-time-class {
    min-width: 140px;
}

// range classes
.horizontal-range-class {
    width: 178px;
    height: 10px;
    padding-right: 0px;
    padding-left: 0px;
}

.vertical-range-class {
    width: 257px;
    height: 10px;
    padding-right: 0px;
    padding-left: 0px;
}

// custom class
.well-control-timer-cycle-input-hr {
    width: 101px;
    padding-right: 30px;
}

.well-control-timer-cycle-input-min {
    width: 101px;
    padding-right: 43px;
}

.well-control-timer-cycle-input-percent {
    width: 98px;
    padding-right: 27px;
}

.well-control-timer-cycle-textArea-confirmation {
    width: 452px;
    height: 146px;
}

.well-control-link-text {
    width: 218px;
}

.well-control-timer-cycle-range-percent {
    width: 110px;
}

.well-control-smart-timer-input {
    width: 101px;
}

// add wells
.add-well-api-search-custom-class {
    width: 352px;
}

.add-well-api-search-fai {
    background-color: base.$ptr-color-primary;
    cursor: pointer;
}

// add wells action panel
.add-well-action-panel-coordinate-input {
    width: 149px;
}

// well index - table  custom classes
.well-index-paginatino-wells-per-page-input {
    width: 75px;
    position: relative;
    top: 13px;
}

// iwp - well details chart
.well-details-chart-date-interval-input {
    width: 100px;
    font-size: 0.8em;
}

.well-details-chart-date-interval-label {
    font-size: 0.8em;
    text-align: right;
    margin-right: 16px;
    line-height: 32px;
}

// Can be used to style arrow on select. potential browser compatibility.
// .customSelectArrow {
//     background: url('../../../../public/assets/chevronDown.svg') no-repeat right ;
//     -webkit-appearance: 'none';
//     background-position-x: calc(100% - 12px);
// }

.well-index-paginator-page-selector {
    width: 70px;
}

.well-index-paginator-page-selector-label {
    min-width: 0px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
