@use "../../../../../../styles/base";

.tHeadContainer {
    width: 100%;
}

.tableHeaderRow {
    box-sizing: border-box;
    border-top: 1px base.$ptr-divider-type base.$ptr-divider-color;
    height: 50px;
    font-size: 16px;
    color: base.$ptr-font-color-dark;
    font-weight: 700;
    background-color: base.$ptr-gray-100;
}

.tHeadContainer th {
    box-sizing: border-box;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ruleName_Header {
    width: 300px;
}

.well_Header {
    width: 550px;
}

.email_Header {
    width: 150px;
}

.text_Header {
    width: 150px;
}

.menu_Header {
    width: 125px;
}

.headerText {
    margin-right: 8px;
}

.sorted {
    color: base.$ptr-blue-600;
}
