@use "../../../styles/base";

// border-color: $ptr-navbar-button-border-color;

.nav-controls-base-class {
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: base.$ptr-navbar-button-radius;
    outline: none;
    border-width: base.$ptr-navbar-button-border-width;
    border-style: base.$ptr-navbar-button-border-style;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid transparent;
    cursor: pointer;
}

// nav bar controls classes
.nav-bar-controls-default {
    color: base.$ptr-white;
    background-color: transparent;
}

.nav-bar-controls-default:hover {
    border-width: base.$ptr-navbar-button-border-width;
    border-style: base.$ptr-navbar-button-border-style;
    border-color: base.$ptr-gray-50;
}

.nav-bar-controls-default:disabled {
    opacity: 0.5;
}

.nav-bar-controls-selected {
    background-color: base.$ptr-gray-50;
    color: base.$ptr-font-color-dark;
}

.nav-bar-controls-selected:disabled {
    background-color: base.$ptr-gray-50;
    opacity: 0.5;
}

// sub nav controls classes
.sub-nav-controls-default {
    border-width: base.$ptr-navbar-button-border-width;
    border-style: base.$ptr-navbar-button-border-style;
    border-color: base.$ptr-button-background-color;
    color: base.$ptr-button-background-color;
    background-color: transparent;
}

.sub-nav-controls-default:hover {
    color: base.$ptr-button-hover-background-color;
    border-color: base.$ptr-button-hover-background-color;
}

.sub-nav-controls-default:disabled {
    opacity: 0.5;
}

.sub-nav-controls-selected {
    background-color: #e3f2fd;
    border-width: base.$ptr-navbar-button-border-width;
    border-style: base.$ptr-navbar-button-border-style;
    border-color: base.$ptr-button-background-color;
    color: base.$ptr-button-background-color;
    box-shadow: 0px 0px 0px 4px #90caf9;
}

.sub-nav-controls-selected:disabled {
    background-color: base.$ptr-blue-gray-700;
    border-color: base.$ptr-blue-gray-700;
    color: base.$ptr-white;
    box-shadow: none;
}

// icon classes
.nav-controls-with-icon-left {
    padding-right: 4px;
    font-weight: 900;
}

.nav-controls-with-icon-right {
    padding-left: 4px;
    font-weight: 900;
}

.wellControlHisotryNavigation {
    // used to adjust height and width
    box-sizing: border-box;
    height: 68px;
    width: 101px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
}
