@use "../../../../styles/base";

.navControlsContainer {
    padding-left: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
}

.navControlOverview {
    margin-right: 16px;
}
