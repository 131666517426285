@use "../../../../../styles/base";

.action-panel-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
}
