@use "../../../../styles/base";

.container {
    justify-content: flex-end;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 32px;
    width: 100%;
    height: 83px;
    min-height: 83px;
    border-bottom: 1px solid base.$ptr-divider-color;
}

.inputContainer {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 24px;
}
