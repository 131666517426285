@use "../../../styles/base";
@use "../../../styles/breakpoint";

.modalContainer {
    width: 500px;
}

.bodyContainer {
    padding: 16px;
}

.latLongRow {
    display: flex;
    flex-direction: row;
}

.latitudeInput {
    padding-right: 16px;
}

.noApiRow {
    margin-bottom: 24px;
}
