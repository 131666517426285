@use "../../../styles/base";

/** Global row classes*/
tr.tableRowClass {
    display: flex;
    box-sizing: border-box;
    height: 50px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: base.$ptr-font-color-dark;
}

/** Global cell calsses */
td.tableCellClass {
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: base.$ptr-font-color-dark;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.tdCellSpan {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/** 
* End globals 
*/

/**
* well index rows. 
*/

td.wellIndexTable_statusIcon {
    min-width: 80px;
    text-align: center;
    vertical-align: middle;
}

td.wellIndexTable_wellName {
    width: 100%;
    min-width: 250px;
}

td.wellIndexTable_runMode {
    min-width: 162px;
    color: base.$ptr-font-color-link;
}

td.wellIndexTable_wellStatus {
    min-width: 185px;
}

td.wellIndexTable_runTime {
    min-width: 160px;
}

td.wellIndexTable_spm {
    min-width: 120px;
}

td.wellIndexTable_rtwk {
    min-width: 202px;
    overflow: hidden;
}

td.wellIndexTable_menu {
    min-width: 49px;
    text-align: center;
    cursor: pointer;
}

tr.DivergentClass {
    background-color: #ffebee;
}

tr.CompliantClass {
    background-color: #fff;
}

/** 
* Admin - organization cells 
*/
td.cellOrganization {
    width: calc(100% - 350px);
    // Max width is max width of table 1117px - remaining admin organization columns - (see tableBuilder.module.scss for max width)
    max-width: 767px;
    min-width: 200px;
    color: base.$ptr-font-color-link;
}

td.cellOrgStatus {
    width: 350px;
}

/** 
* Admin - user cells 
*/
td.cellUserUser {
    width: calc(100% - 264px - 130px - 140px);
    min-width: 200px;
    // Max width is max width of table 1117px - remaining admin user columns - (see tableBuilder.module.scss for max width)
    max-width: 583px;
    color: base.$ptr-font-color-link;
}

td.cellUserEmail {
    width: 264px;
}

td.cellUserRole {
    width: 130px;
}

td.cellUserUserStatus {
    width: 140px;
}
