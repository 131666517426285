@use "../../../../../styles/base";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    color: base.$ptr-font-color-dark;
}
