.container {
    width: 500px;
}
.profileBodyContainer {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
}

.marginRight {
    margin-right: 8px;
}

.marginLeft {
    margin-left: 8px;
}
