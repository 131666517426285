@use "../../../../../styles/base";

.container {
    box-sizing: border-box;
    width: 768px;
    min-width: 500px;
}

.queryLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 768px;
    height: 400px;
}

.queryError {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 768px;
    height: 400px;
    color: base.$ptr-color-error;
    font-size: 24px;
}

.queryEmpty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 768px;
    height: 400px;
    color: base.$ptr-blue-gray-800;
    font-size: 24px;
}

.formContainer {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    font-family: "Roboto";
    color: #424242;
    display: flex;
    flex-direction: row;
}

.marginRight16 {
    margin-right: 16px;
}
