@use "../../../styles/base";
@use "../../../styles/breakpoint";

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint.sm {
        margin-top: 16px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
    width: 80%;
    box-sizing: border-box;
}

.notesList {
    box-sizing: border-box;
    width: 80%;
    overflow-y: auto;
    border: 1px solid #cfd8dc;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    max-height: calc(
        100vh - 260px
    ); // Adjust on max height of notes list that does not overflow
}

.noteCard {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 12px;
    padding: 12px;
    background-color: #fafafa;
    border-radius: 8px;
    color: #37474f;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #cfd8dc;
}

.noteContent {
    width: 80%;
    max-height: 150px;
    overflow-y: auto;
}

.noteMeta {
    width: 20%;
    font-size: 12px;
    color: #546e7a;
    text-align: right;
}

.updateContainer {
    margin-left: 8px;
}

.loader {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    font-size: 24px;
    margin-top: 24px;
    height: 200px;
}

.error {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    font-size: 24px;
    color: base.$ptr-color-error;
    padding-left: 8px;
}

.emptyStateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.emptyTitle {
    color: #37474f;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
