@use "../../styles/base";
@use "../../styles/breakpoint";

.pageContainer {
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 64px); /** View port - nav bar */
    background-color: #eceff1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: auto;
}

.bodyContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    max-width: 720px;
}

.userProfileContainer {
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    padding: 24px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.sectionTitle {
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 500;
}

.sectionTitleRow {
    display: flex;
    flex-direction: row;
}

.sectionTitleColumn {
    display: flex;
    flex-direction: column;
}

.userProfileInfo {
    box-sizing: border-box;
    justify-content: space-between;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
    border-radius: 4px;
    padding-top: 16px;
    margin-bottom: 16px;
}

.userProfileInfoRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 16px;
}

.userProfileInfoColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.userProfileInfoContainer {
    box-sizing: border-box;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.userProfileInfoContainerRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.userProfileInfoContainerColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%;
}

.userProfileInfoHeading {
    box-sizing: border-box;
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
}

.userProfileInfoValue {
    box-sizing: border-box;
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    font-weight: 700;
}

.notificationContainer {
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.preferencesTitleRow {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.preferencesValue {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}

.editButton {
    // aligns the button to the bottom right of the parent container
    // uses flex
    justify-self: end;
}

.messageDisabled {
    height: 0px;
    width: 0px;
}

.preferencesRow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
}

.preferencesRowLabel {
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
    align-content: center;
    margin-right: 8px;
}

.preferencesRowValue {
    font-size: 16px;
    font-weight: 700;
    color: #37474f;
    margin-right: 8px;
}

.preferencesRowValueTime {
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
    min-width: 4.2rem;
    display: flex;
    justify-content: right;
    // background should be a gray box with slight rounding on corners. border is thin and slightly darker than the background
    border-radius: 4px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #f7f7f7;
    padding: 8px;
}

.emailPreferencesRowValue {
    font-size: 16px;
    font-weight: 700;
    color: #37474f;
    min-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    white-space: nowrap;
}

.preferencesSection {
    padding-top: 1rem;
}

.preferencesSectionContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0.3rem;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
    padding: 16px;
    border-radius: 4px;
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}

.preferencesSectionTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.preferencesRowActions {
    // parent is a flex container, i want to be 100% the width of the parent
    // align all contents starting from the right
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.disclaimerText {
    align-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: base.$ptr-font-color-dark;
    font-size: 12px;
    font-weight: 400;
    margin-top: 1rem;
}

.passwordContainer {
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.passwordSubContainer {
    width: 300px;
    padding: 16px;
    border-radius: 4px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    background-color: #fafafa;
}

.passwordResetHeading {
    margin-bottom: 16px;
    color: black;
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
}

.settingsValueRow {
    display: flex;
    flex-direction: row;
}

.separator {
    border-bottom: 1px solid base.$ptr-divider-color;
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 1rem;
}

.dndActive {
    color: #4caf50;
    text-transform: uppercase;
    font-weight: 400;
    min-width: 90px;
}

.dndInactive {
    color: #f44336;
    text-transform: uppercase;
    font-weight: 400;
    min-width: 90px;
}

.emailWarning {
    color: #f44336;
    font-size: 14px;
    font-weight: 400;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.alertBannerMessage {
    width: 350px;

    @media (max-width: 500px) {
        width: 300px;
    }
    @media (max-width: 375px) {
        width: 250px;
    }
}

.supportLink {
    padding-left: 4px;
    cursor: pointer;
}

.emailToolTipTrigger {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.popoverTrigger {
    background: transparent;
    border: none;
}
