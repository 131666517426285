@use "../../styles/base";

.container {
    height: 500px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    margin-bottom: 8px;
}
