.formContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.marginTop8 {
    margin-top: 8px;
}

.marginTop4 {
    margin-top: 4px;
}

.toggleContainer {
    width: 100%;
}
