@use "../../styles/base";

.bodyContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.padRight16 {
    padding-right: 16px;
}

.parentOrganizationIdLabel {
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    margin-right: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.activationContainer {
    margin-top: 20px;
}

.labelFont {
    font-weight: 700;
    padding-left: 5px;
}
