.container {
    padding: 24px;
}

.boldHeading {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #37474f;
}

.heading {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}

.timezoneInputLabel {
    font-weight: 700;
    color: #37474f;
    margin-bottom: 2px;
}

.timezoneInputMessage {
    min-height: 0px;
}

.checkboxContainer {
    margin-bottom: 16px;
    margin-left: -8px;
}
