@use "../../../../styles/base";

.container {
    width: 500px;
}

.queryLoading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 200px;
}

.queryError {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: base.$ptr-color-error;
    font-size: 24px;
    padding: 24px;
    min-height: 200px;
}

.queryEmpty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: base.$ptr-blue-gray-800;
    font-size: 24px;
    min-height: 200px;
}

.bodyContainer {
    box-sizing: border-box;
    padding: 24px;
    padding-bottom: 8px;
    color: #424242;
}

.addSilenceBodyContainer {
    min-height: 150px;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.initalSilenceRow {
    margin-bottom: 16px;
    font-weight: 500;
}

.titleText {
    font-weight: 700;
    font-size: 16px;
}

.silenceLabel {
    width: 200px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.marginRight4 {
    margin-right: 4px;
}

.marginRight8 {
    margin-right: 8px;
}

.valueInput {
    width: 86px;
}

.unitInput {
    width: 86px;
    margin-left: 8px;
}

.bodyText {
    font-weight: 400;
    font-size: 16px;
    color: #838383;
}

.silenceUntilRow {
    margin-top: 16px;
}

.removeSilenceRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;
}

.subscriptionDetails {
    margin-bottom: 16px;
    display: flex;
}

.subscriberLabel {
    margin-right: 16px;
}

.fai {
    color: #00aaff;
    width: 16px;
    height: 16px;
}
