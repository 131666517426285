.fai {
    width: 16px;
    height: 16px;
}

.red {
    color: #f44336;
}

.gray {
    color: #90a4ae;
}
