.triggerStyles {
    width: 100%;
}

.triggerChildren {
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.ruleNameTrigger {
    font-size: 16px;
    display: inline;
    vertical-align: middle;
    margin-right: 8px;
}
