@use "../../../../styles/base";

th.tableHeaderClass {
    height: 50px;
    max-height: 50px;
    display: block;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
    font-size: 16px;
    line-height: 24px;
    color: base.$ptr-font-color-dark;
    font-weight: 700;
    padding: 12px;
    background-color: base.$ptr-gray-100;
    text-align: left;
    padding-left: 12px;
}

/** Admin - organization headers */
th.headerOrganization {
    width: calc(100% - 350px);
    min-width: 200px;
}

th.headerOrgStatus {
    width: 350px;
}

/** Admin - user headers */
th.headerUserUser {
    width: calc(100% - 264px - 130px - 140px);
    min-width: 300px;
}

th.headerUserEmail {
    width: 264px;
}

th.headerUserRole {
    width: 130px;
}

th.headerUserUserStatus {
    width: 140px;
}
