@use "../../../styles/base";

.bodyHeader {
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
}

.container {
    box-sizing: border-box;
    width: 100%;
    max-width: 720px;
    height: 431px;
    background-color: base.$ptr-gray-100;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    border-radius: 4px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 37px;
    padding-left: 16px;
    padding-right: 16px;
    overflow-y: auto;
    color: base.$ptr-font-color-dark;
}

.rowContainer {
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    height: 203px;
    margin-top: 16px;
    background-color: base.$ptr-white;
    border-radius: base.$ptr-radius-round;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.row {
    display: flex;
    flex-direction: row;
    font-family: "Roboto";
    font-style: normal;
    margin-bottom: 16px;
}

.rowText {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 182px;
    padding-right: 16px;
}

.rowHeading {
    width: 98px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.rowTextWellName {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 98px);
    padding-right: 16px;
}

.apiRow {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 25px;
}
