.triggerDefault {
    cursor: pointer;
}

.menuContentDefault {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 15%);
    border-radius: 0.25rem;
    padding: 0.5rem 0 0.5rem 0;
    z-index: 1000;
    color: rgb(66, 66, 66);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    min-width: 200px;
    z-index: 100;
    background-color: #fff;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

.menuContentDefault[data-side="top"] {
    animation-name: slideDownAndFade;
}
.menuContentDefault[data-side="right"] {
    animation-name: slideLeftAndFade;
}
.menuContentDefault[data-side="bottom"] {
    animation-name: slideUpAndFade;
}
.menuContentDefault[data-side="left"] {
    animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
