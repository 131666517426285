@use "../../../../../styles/base";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: base.$ptr-font-color-dark;
}

.informationContainer {
    box-sizing: border-box;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.padRight19 {
    padding-right: 19px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textRow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    height: 20px;
}

.textHeading {
    font-weight: 700;
    width: 90px;
}

.subtext {
    font-weight: 400;
}
