@use "../../styles/base";

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px #{base.$global-val}px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-top-style: base.$ptr-divider-type;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
}

.arrowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textContainer {
    padding-left: 12px;
    padding-right: 12px;
    font-family: "Roboto";
    font-size: 16px;
    color: #000000;
}
