@use "../../../../../styles/base";
@use "../../../../../styles/breakpoint";

.emptyStateContainer {
    box-sizing: border-box;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    color: base.$ptr-blue-gray-800;
    background-color: #fff;
}

.emptyTitle {
    font-weight: 700;
    font-size: 24px;
    margin-top: 24px;
}

.addSubscriptionButton {
    font-weight: 400;
    font-size: 16px;
    margin-top: 24px;
}
