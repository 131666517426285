@use "../../../styles/base";

.display-panel-empty-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.display-panel-empty-fai {
    font-weight: 900;
    width: 82px;
    height: 82px;
    color: base.$ptr-blue-gray-300;
    margin-top: 100px;
}

.display-panel-empty-heading-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 8px;
    text-align: center;
    color: base.$ptr-font-color-dark;
}

.display-panel-empty-subtext {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #6c757d;
}
