@use "../../../styles/base";
@use "../../../styles/breakpoint";

.run-time {
    color: #02C944;
}

.idle-time {
    color: #0398FC;
}

.not-available {
    color: #BF42F5;
}

.container {
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    padding: 16px;
    @include breakpoint.sm {
        padding: 0px;
    }
}

.chartTitle {
    display: flex;
    flex-direction: row;
}

.notAvailableTitle {
    background-color: #bf42f5;
    color: #f8f8f8;
    margin-right: 4px;
    padding-left: 4px;
    padding-right: 4px;
}

.notAvailableLabel {
    font-size: 0.8em;
    text-align: left;

    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 4px 8px;
}

.motionChartDateLabel {
    font-size: 1.2em;
    font-weight: 500;
    color: base.$ptr-blue-gray-800;
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingText {
    margin-bottom: 16px;
    color: base.$ptr-blue-gray-600;
}

.emptyDataText {
    color: #37474f;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin: 0 36px;
}

.error {
    font-size: 24px;
    color: base.$ptr-color-error;
}

.dateIntervalContainer {
    position: relative;
    top: -20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.timezoneLabel {
    font-size: 0.8em;
    text-align: left;
    a {
        color: base.$ptr-color-primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.noDataInGraphViewWarning {
    width: 100%;
    color: #f44336;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    margin-top: 8px;
    text-align: center;
}
