@use "../../../styles/base";
@use "../../../styles/breakpoint";

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: base.$ptr-blue-gray-700;
    width: 500px;
}

.bodyHeading {
    color: base.$ptr-blue-gray-700;
    box-sizing: border-box;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.wellName {
    font-weight: 700;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
}

.wellApi {
    font-weight: 400;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
}

.inputContainer {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    margin-bottom: 23px;
    height: 100px;
}

.marginRight {
    margin-right: 16px;
}
