@use "../../../../../styles/base";
@use "../../../../../styles/breakpoint";

.container {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: "Roboto";
    color: #424242;
}

.actionContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.actionContainerLeft {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.error {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    font-size: 24px;
    color: base.$ptr-color-error;
    background-color: #fff;
}

.loader {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.emptyStateContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    color: base.$ptr-blue-gray-800;
    background-color: #fff;
    padding: 16px;
}

.emptyTitle {
    font-weight: 400;
    font-size: 22px;
}

.previewOuterContainer {
    box-sizing: border-box;
    margin-top: 16px;
    height: 200px;
    border: 1px solid #dcdcdc;
    height: 125px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.previewDataContainer {
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
}

.previewRowContainer {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    width: 100%;
    margin-bottom: 4px;
}

.previewTitle {
    font-weight: 700;
}

.padWellName {
    width: 75%;
    overflow-x: auto;
    margin-right: 8px;
}

.marginRight16 {
    margin-right: 16px;
}

.previewDescription {
    color: #838383;
    font-size: 14px;
    margin-top: 4px;
    font-weight: 400;
}
