@use "../../styles/base";

.container {
    width: 500px;
    min-width: 500px;
}

.bodyContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.organizationContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
    margin-bottom: 16px;
    border-bottom-width: 1px;
    border-color: base.$ptr-divider-color;
    border-bottom-style: base.$ptr-divider-type;
}

.organizationTitle {
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    margin-right: 8px;
    box-sizing: border-box;
    width: 105px;
    padding-top: 6px;
}

.instructions {
    padding-bottom: 16px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.row_leftContainer {
    box-sizing: border-box;
    width: 50%;
    padding-right: 8px;
}

.row_rightContainer {
    box-sizing: border-box;
    width: 50%;
    padding-left: 8px;
}
