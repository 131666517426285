// -----------------------------
// Break Points / Media Queries
// -----------------------------

// Small tablets and large smartphones
$screen-sm: 768px;

// Tablets and small desktops
$screen-md: 992px;

// Large tablets and desktops
$screen-lg: 1258px;

// Mix Ins
// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-lg}) {
        @content;
    }
}
