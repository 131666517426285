@use "../../../../../styles/base";

.bodyContainer {
    box-sizing: border-box;
    padding: 24px;
    color: #424242;
    min-height: 350px;
}

.metricContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.metricTitleContainer {
    width: 200px;
}

.metricInput {
    width: 50%;
}

.timeRangeContainer {
    box-sizing: border-box;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.timeRangeTitle {
    width: 200px;
}

.timeRangeInput {
    width: 50%;
}

.ruleOptionsContainer {
    box-sizing: border-box;
    padding: 16px;
    margin-top: 24px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.ruleOptionTitle {
    width: 150px;
}

.ruleNameInput {
    width: calc(100% - 150px); // width - 150px for the label
    position: relative;
    top: 13px;
}

.ruleDescriptionInput {
    width: calc(100% - 150px); // width - 150px for the label
    max-width: calc(100% - 150px);
    height: 70px;
    max-height: 70px;
}

.ruleOptionTitleInputContainerForThreshold {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 16px;
    height: 70px;
    align-items: normal;
}

.ruleOptionTitleInputContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 16px;
    align-items: center;
}

.ruleOptionConditionUnit {
    box-sizing: border-box;
    width: 80px;
    margin-right: 8px;
}

.ruleOptionThreshold {
    box-sizing: border-box;
    width: 80px;
    margin-right: 8px;
}

.thresholdInputMessage {
    font-weight: 400;
    font-size: base.$ptr-font-size-x-small;
    color: base.$ptr-font-color-medium;
    min-height: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-right: 8px;
}

.thresholdInputMessageDanger {
    font-weight: 400;
    font-size: base.$ptr-font-size-x-small;
    border-color: base.$ptr-color-error;
    min-height: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-right: 8px;
    max-width: 80px;
}

.titleText {
    font-weight: 700;
    font-size: 16px;
}

.learnMore {
    font-size: 12px;
    color: #424242;
    text-decoration: none;
}

.marginRight4 {
    margin-right: 4px;
}

.marginRight8 {
    margin-right: 8px;
}

.thresholdInstructionText {
    box-sizing: border-box;
    margin-right: 8px;
    max-width: calc(100% - 246px);
    position: relative;
    top: 5px;
}

.thersholdInstructionText_outsideRange {
    box-sizing: border-box;
    margin-right: 8px;
    max-width: calc(
        100% - 422px
    ); // width - 150px for the label - 88px per input (up to three total) - 8px for the margin
    position: relative;
    top: 5px;
}

.alignThresholdText {
    position: relative;
    top: 5px;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.disabled {
    opacity: 0.75;
}

.metricDescription {
    color: #838383;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
}

.metricDescriptionDisabled {
    color: #838383;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
}

.fai {
    width: 16px;
    height: 16px;
    color: #00aaff;
    margin-top: 2px;
}
