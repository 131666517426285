@use "../../../styles/base";

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.leftContainer {
    box-sizing: border-box;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-right-style: base.$ptr-divider-type;
    width: 506px;
    padding-right: 20px;
}

.leftContainerText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: base.$ptr-font-color-medium;
}

.rightContainer {
    box-sizing: border-box;
    width: 210px;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
}

.organizationLabel {
    margin-right: 8px;
    padding-top: 2px;
    display: inline-block;
    min-width: 124px;
    height: 24px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: base.$ptr-input-label-font-size;
    line-height: base.$ptr-input-label-line-height;
    color: base.$ptr-input-label-font-color;
}

.fai {
    width: 16px;
    height: 16px;
    color: #9e9e9e;
}
