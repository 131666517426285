@use "../../styles/base";

.container {
    box-sizing: border-box;
    position: relative;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
}

.overflowContainer {
    overflow-y: hidden;
    overflow-x: auto;
}

.tableClass {
    width: 100%;
    border-collapse: collapse;
}

.tableRow {
    display: flex;
    box-sizing: border-box;
    height: 40px;
    border-bottom: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: base.$ptr-font-color-dark;
    overflow: hidden;
}

.tableRow.DivergentClass:nth-child(even),
.tableRow.CompliantClass:nth-child(even) {
    filter: brightness(
        95%
    ); /* Slightly darker background color for even rows */
}

.tableRow.DivergentClass:hover,
.tableRow.CompliantClass:hover {
    filter: brightness(90%); /* Slightly darker background color for on hover*/
}

.tableCell {
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: base.$ptr-font-color-dark;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.cellSpan {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.alert_Cell {
    width: 80px;
}

.wellName_Cell {
    width: calc(100% - 80px - 162px - 185px - 160px - 120px - 202px - 98px);
    min-width: 250px;
}

.runMode_Cell {
    width: 162px;
}

.wellStatus_Cell {
    width: 185px;
}

.runTime_Cell {
    width: 160px;
}

.spm_Cell {
    width: 120px;
}

.runTimeWeeklyTrends_Cell {
    width: 202px;
    overflow: hidden;
}

.menu_Cell {
    width: 98px;
}

.DivergentClass {
    background-color: #ffebee;
}

.CompliantClass {
    background-color: #fff;
}

.deferredRow {
    opacity: 0.5;
    transition: opacity 0.2s 0.2s linear;
}
