@use "../../../../../styles/base";
@use "../../../../../styles/breakpoint";

.tableContainer {
    box-sizing: border-box;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    max-width: 2560px;
    @include breakpoint.sm {
        border-top-style: none;
    }
    @include breakpoint.md {
        border-top-style: none;
    }
}

.overflowContainer {
    overflow-y: hidden;
    overflow-x: auto;
}

.tableClass {
    width: 100%;
    border-collapse: collapse;
}

.tableRow {
    display: flex;
    box-sizing: border-box;
    height: 38px;
    border-bottom: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: base.$ptr-font-color-dark;
    overflow: hidden;
    background-color: #fff;
}

.tableRow:nth-child(even) {
    filter: brightness(
        95%
    ); /* Slightly darker background color for even rows */
}

.tableRow:hover {
    filter: brightness(90%); /* Slightly darker background color for on hover*/
}

.tableCell {
    box-sizing: border-box;
    padding-left: 12px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: base.$ptr-font-color-dark;
    display: flex;
    align-items: center;
}

.cellSpan {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ruleName_Cell {
    width: 300px;
}

.metric_Cell {
    width: 350px;
}

.description_Cell {
    min-width: 350px;
    width: calc(
        100vw - 1050px
    ); // 50px paddding around table, 25px for scrollbar vertical scroll bar, 975px for other columns
    overflow: hidden;
}

.lastAlert_Cell {
    width: 200px;
}

.menu_Cell {
    width: 125px;
    display: flex;
}

.deferredRow {
    opacity: 0.5;
    transition: opacity 0.2s 0.2s linear;
}

.silencedCell {
    color: #cacaca;
}
