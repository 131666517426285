@use "../../../../../styles/base";

.bodyContainer {
    box-sizing: border-box;
    padding: 24px;
    color: #424242;
}

.selectContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.titleLabel {
    width: 150px;
    font-weight: 700;
    font-size: 16px;
}

.wellsContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}

.includeNewWells {
    margin-left: 143px;
}

.communicationsChannelContainer {
    box-sizing: border-box;
    padding: 16px;
    margin-top: 24px;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
}

.commChannelTitle {
    font-weight: 700;
    font-size: 16px;
}

.commChanSecondaryText {
    font-size: 16px;
    font-weight: 400;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxLabel {
    width: 100px;
    margin-right: 16px;
    font-weight: 700;
}

.marginBottom8 {
    margin-bottom: 8px;
}

.marginBottom16 {
    margin-bottom: 16px;
}

.styles.byPass {
    margin-left: 16px;
}

.marginRight4 {
    margin-right: 4px;
}

.marginLeft4 {
    margin-left: 4px;
}

.disabled {
    opacity: 0.75;
    color: #909090;
}

.fai {
    width: 16px;
    height: 16px;
    color: #00aaff;
}
