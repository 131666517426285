@use "../../../styles/base";

.container {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border: solid;
    background-color: base.$ptr-gray-400;
    border-radius: base.$ptr-radius-round;
    border-width: base.$ptr-divider-width;
    border-color: base.$ptr-divider-color;
    border-style: base.$ptr-divider-type;
    box-sizing: border-box;
    color: base.$ptr-gray-800;
    padding: 24px;
}

.errorMessage {
    color: red;
    padding-top: 8px;
}

.title {
    box-sizing: border-box;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: base.$ptr-blue-gray-800;
}

.buttonContainer {
    margin-top: 16px;
}

a.link {
    text-decoration: none;
    color: white;
}

.footerText {
    margin-top: 16px;
}
