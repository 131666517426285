@use "../../../styles/base";

.tHeadContainer {
    width: 100%;
}

.tableHeaderRow {
    border-bottom: 1px base.$ptr-divider-type base.$ptr-divider-color;
    height: 50px;
    box-sizing: border-box;
    font-size: 16px;
    color: base.$ptr-font-color-dark;
    font-weight: 700;
    background-color: base.$ptr-gray-100;
}

.tHeadContainer th {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
    box-sizing: border-box;
}

.alert_Header {
    width: 80px;
}

.wellName_Header {
    width: calc(100% - 80px - 162px - 185px - 160px - 120px - 202px - 98px);
    min-width: 250px;
}

.runMode_Header {
    width: 162px;
}

.wellStatus_Header {
    width: 185px;
}

.runTime_Header {
    width: 160px;
}

.spm_Header {
    width: 120px;
}

.runTimeWeeklyTrends_Header {
    width: 202px;
    white-space: nowrap;
}

.menu_Header {
    width: 98px;
}

.headerText {
    margin-right: 8px;
}

.sorted {
    color: base.$ptr-blue-600;
}
