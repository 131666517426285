@use "../../../../styles/base";

.action-panel-edit-flex-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.action-panel-edit-pad-left-4 {
    padding-left: 4px;
}

.action-panel-edit-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.action-panel-edit-well-information-container {
    box-sizing: border-box;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.action-panel-edit-text-row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    height: 20px;
}

.action-panel-edit-text-heading {
    font-weight: 700;
    width: 101px;
}

.action-panel-edit-subtext {
    font-weight: 400;
}

.action-panel-tool-tip-api-heading {
    padding: 0px 8px;
    display: inline;
}

.action-panel-edit-pad-right-19 {
    padding-right: 19px;
}
