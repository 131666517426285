@use "../../styles/base";
@use "../../styles/breakpoint";

.container {
    background-color: base.$ptr-gray-300;
    box-sizing: border-box;
    height: calc(100vh - base.$ptr-navbar-height); /** View port - nav bar */
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.bodyContainer {
    display: flex;
    flex-direction: row;
}

.iwpOutletContainer {
    box-sizing: border-box;
    width: 100vw;
    padding: 24px;
    @include breakpoint.sm {
        padding: 0px;
    }
}

.iwpSideBarContainerActive {
    width: calc(100vw - 268px);
}

.sidebarContainer {
    width: 268px;
    @include breakpoint.sm {
        width: 100vw;
    }
    @include breakpoint.md {
        width: 100vw;
    }
}
