@use "../../../styles/base";

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: base.$ptr-button-height;
    font-size: base.$ptr-button-font-size;
    font-weight: base.$ptr-button-font-weight;
    line-height: base.$ptr-button-line-height;
    border-radius: base.$ptr-radius-round;
    border-width: base.$ptr-button-border-width;
    border-style: base.$ptr-button-border-style;
    padding: base.$ptr-button-padding-y base.$ptr-button-padding-x;
}

.button:hover:enabled {
    cursor: pointer;
}

.button-icon-padding-right {
    padding-right: 0.5rem;
}

/* Primary Default */
.button-primary-default {
    color: base.$ptr-button-color;
    background-color: base.$ptr-button-background-color;
    border-color: base.$ptr-color-primary;
}

.button-primary-default:hover:enabled {
    background-color: base.$ptr-button-hover-background-color;
    color: base.$ptr-button-hover-color;
    border-color: base.$ptr-button-hover-border-color;
}

.button-primary-default:disabled {
    opacity: base.$ptr-button-disabled-opacity;
    border-color: base.$ptr-button-background-color;
}

.button-primary-default:focus {
    background: base.$ptr-button-focus-background-color;
    color: base.$ptr-button-focus-color;
    box-shadow: base.$ptr-button-focus-box-shadow;
    outline: none;
    border-color: base.$ptr-button-focus-border-color;
}

.button-primary-default:active:enabled {
    background-color: base.$ptr-button-active-background-color;
    border-color: base.$ptr-button-active-border-color;
    color: base.$ptr-button-active-color;
    box-shadow: none;
}

/*  Primary Alert */
.button-primary-alert {
    color: base.$ptr-button-danger-color;
    background-color: base.$ptr-button-danger-background-color;
    border-color: #e53935;
}

.button-primary-alert:hover:enabled {
    color: base.$ptr-button-danger-hover-color;
    background-color: base.$ptr-button-danger-hover-background-color;
    border-color: base.$ptr-button-danger-hover-border-color;
}

.button-primary-alert:disabled {
    opacity: base.$ptr-button-disabled-opacity;
}

.button-primary-alert:focus {
    outline: none;
    background-color: base.$ptr-button-danger-focus-background-color;
    color: base.$ptr-button-danger-focus-color;
    border-color: base.$ptr-button-danger-focus-border-color;
    box-shadow: 0px 0px 0px 4px rgba(229, 57, 53, 0.5);
}

.button-primary-alert:active:enabled {
    color: base.$ptr-button-danger-active-color;
    background-color: base.$ptr-button-danger-active-background-color;
    border-color: base.$ptr-button-danger-active-border-color;
    box-shadow: none;
}

/*  Secondary Default  */
.button-secondary-default {
    color: base.$ptr-button-secondary-color;
    background-color: base.$ptr-button-secondary-background-color;
    border-color: base.$ptr-button-secondary-border-color;
}

.button-secondary-default:hover:enabled {
    color: base.$ptr-button-secondary-hover-color;
}

.button-secondary-default:disabled {
    opacity: base.$ptr-button-disabled-opacity;
}

.button-secondary-default:focus {
    color: base.$ptr-button-secondary-focus-color;
    border-color: base.$ptr-button-secondary-focus-border-color;
    box-shadow: base.$ptr-button-focus-box-shadow;
    outline: none;
}

.button-secondary-default:active:enabled {
    color: base.$ptr-button-secondary-active-color;
    background-color: base.$ptr-button-secondary-active-background-color;
    border-color: base.$ptr-button-secondary-active-border-color;
    box-shadow: none;
    outline: none;
}

/*  Secondary Alert  */
.button-secondary-alert {
    background: transparent;
    color: #e53935;
    border: 0px;
}

.button-secondary-alert:hover:enabled {
    color: #d32f2f;
}

.button-secondary-alert:disabled {
    opacity: 0.5;
}

.button-secondary-alert:focus {
    outline: none;
    color: #c62828;
    box-shadow: 0px 0px 0px 4px rgba(229, 57, 53, 0.5);
}

.button-secondary-alert:active:enabled {
    color: #b71c1c;
    border: 0px;
    box-shadow: none;
}

.iwp-subNavigation-custom-button {
    font-size: 20px;
    font-weight: 500;
}

.button-table-row-outlined {
    height: 30px;
    border-color: base.$ptr-button-secondary-color;
}

.button-table-row-outlined:hover:enabled {
    color: base.$ptr-button-color;
    background-color: base.$ptr-button-secondary-color;
}
.button-table-row-outlined:focus {
    color: base.$ptr-button-secondary-color;
    background-color: base.$ptr-button-secondary-background-color;
    border-color: base.$ptr-button-secondary-color;
    box-shadow: none;
    outline: none;
}
