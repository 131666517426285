@use "../../styles/base";

.container {
    width: 500px;
    min-width: 500px;
}

.bodyContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    width: 100%;
}

.parentOrganizationIdLabel {
    color: base.$ptr-font-color-dark;
    font-size: 16px;
    margin-right: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
}
