@use "../../../../styles/base";
@use "../../../../styles/breakpoint";

.container {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    @include breakpoint.sm {
        padding: 0px;
    }
    @include breakpoint.md {
        padding: 0px;
    }
}

.loader {
    box-sizing: border-box;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    background-color: #fff;
}

.error {
    box-sizing: border-box;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    font-size: 24px;
    color: base.$ptr-color-error;
    background-color: #fff;
}

.emptyStateContainer {
    box-sizing: border-box;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: base.$ptr-divider-width base.$ptr-divider-type
        base.$ptr-divider-color;
    color: base.$ptr-blue-gray-800;
    background-color: #fff;
}

.emptyTitle {
    font-weight: 700;
    font-size: 24px;
    margin-top: 24px;
}
