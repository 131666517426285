.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.message {
    margin-top: 15px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #37474f;
}
